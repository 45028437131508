import React from 'react'

// ----- Images -----
import AirFrance from '../../images/clients/airfrance.png'
import Alstom from '../../images/clients/alstom.png'
import BioMerieux from '../../images/clients/BioMerieux_logo.svg.png'
import BouyguesTelecom from '../../images/clients/Bouygues_Telecom.png'
import Bridgestone from '../../images/clients/bridgestone.png'
import Butagaz from '../../images/clients/butagaz.png'
import Daher from '../../images/clients/daher.png'
import Engie from '../../images/clients/engie.png'
import Lanvin from '../../images/clients/lanvin.png'
import Lhoist from '../../images/clients/lhoist.png'
import FDJ from '../../images/clients/FDJ.png'
import Framatome from '../../images/clients/Framatome-HD.png'
import MairieParis from '../../images/clients/mairie-de-paris.png'
import Micromania from '../../images/clients/micromania.png'
import Nexans from '../../images/clients/nexans.png'
import Orange from '../../images/clients/Orange.png'
import Orano from '../../images/clients/orano.png'
import Printemps from '../../images/clients/printemps.png'
import Sofibel from '../../images/clients/sofibel.png'
import Syngenta from '../../images/clients/syngenta.png'
import Tetris from '../../images/clients/tetris.png'
import Thales from '../../images/clients/Thales.svg.png'
import Total from '../../images/clients/total.png'

import Noz from '../../images/clients/NOZ.png'
import APRR from '../../images/clients/aprr.png'
import Gobain from '../../images/clients/gobain.png'
import Lagardere from '../../images/clients/lagardere.png'
import Dinh from '../../images/clients/dinh.png'
import UPSA from '../../images/clients/upsa.png'
import Hyundai from '../../images/clients/hyundai.jpg'
import Expanscience from '../../images/clients/expanscience.png'


//  ___________________ AUTRES LOGO PRET A L'EMPLOI _______________________
// import Accessite from '../../images/clients/accessite.jpg'
// import Aldi from '../../images/clients/Aldi-Logo.wine.png'
// import AprrArea from '../../images/clients/aprr-area-logo.png'
// import Aptiv from '../../images/clients/aptiv-vector-logo.png'
// import BearingPoint from '../../images/clients/bearing-point.jpg'
// import Brady from '../../images/clients/brady.png'
// import Edf from '../../images/clients/edf.png'
// import Expanscience from '../../images/clients/expanscience.svg.png'
// import Ficofi from '../../images/clients/ficofi.jpg'
// import Galderma from '../../images/clients/Galderma_logo.svg.png'
// import Generali from '../../images/clients/generali_logo.png'
// import GenneveilleirsHabitat from '../../images/clients/genneveilleirs_habitat.png'
// import Hutchinson from '../../images/clients/hutchinson.png'
// import Iter from '../../images/clients/iter.png'
// import Lidl from '../../images/clients/lidl.png'
// import CaisseDesDepots from '../../images/clients/Caisse_des_Depots.png'
// import SaintGobain from '../../images/clients/Saint-Gobain.png'
// import Pandrol from '../../images/clients/pandrol.png'
// import Prisma from '../../images/clients/prisma-v1.jpg'
// import Macif from '../../images/clients/macif-logo.gif'
// import Mobilu from '../../images/clients/mobilu.PNG'
// import Noz from '../../images/clients/NOZ.png'
// import Veolia from '../../images/clients/veolia-environnement-logo.png'



// ----- Packages -----
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


export default function TrustedUs({ trustedUsRef }) {

    const items = [
        // <img src={Total} alt={"Total partenaire"} className="xl-logo" />,
        <img src={Engie} alt={"Engie partenaire"} />,
        <img src={Orano} alt={"Orano partenaire"} />,
        <img src={AirFrance} alt={"Air France partenaire"} className="xxl-logo"/>,
        // <img src={Alstom} alt={"Alstom partenaire"} className="sm-logo" />,
        // <img src={Sofibel} alt={"Sofibel partenaire"} className="xxl-logo"/>,
        <img src={Bridgestone} alt={"Bridgestone partenaire"} />,
        // <img src={Butagaz} alt={"Butagaz partenaire"} />,
        <img src={Daher} alt={"Daher partenaire"} />,
        <img src={Lanvin} alt={"Lanvin partenaire"} className="xxl-logo"/>,
        <img src={Lhoist} alt={"Lhoist partenaire"} className="xl-logo"/>,
        <img src={MairieParis} alt={"Mairie de Paris partenaire"} className="xl-logo"/>,
        <img src={Nexans} alt={"Nexans partenaire"} className="sm-logo"/>,
        <img src={Syngenta} alt={"Syngenta partenaire"} />,
        <img src={Tetris} alt={"Tetris partenaire"} className="xl-logo"/>,
        <img src={Thales} alt={"Thales partenaire"} className="xs-logo"/>,
        // <img src={Orange} alt={"Orange partenaire"} className="xxl-logo"/>,
        <img src={Printemps} alt={"Printemps partenaire"} className="xxl-logo"/>,
        <img src={Framatome} alt={"Framatome partenaire"} />,
        <img src={Micromania} alt={"Micromania partenaire"} className="sm-logo"/>,
        <img src={FDJ} alt={"FDJ partenaire"} />,
        <img src={BouyguesTelecom} alt={"BouyguesTelecom partenaire"} />,
        <img src={BioMerieux} alt={"Bio Merieux partenaire"} />,

        <img src={Noz} alt={"NOZ"} className="sm-logo" />,
        <img src={APRR} alt={"APRR"} />,
        <img src={Gobain} alt={"Saint Gobain"} className="sm-logo" />,
        <img src={Lagardere} alt={"Lagardere"} />,
        <img src={Dinh} alt={"dinh van"} className="sm-logo" />,
        <img src={UPSA} alt={"UPSA"} />,
        <img src={Hyundai} alt={"Hyundai"} />,
        <img src={Expanscience} alt={"Expanscience"} className="sm-logo" />,
    ];




    return (
        <div id="trustedUs" ref={trustedUsRef} className="component">

            <h2>Ils nous ont fait confiance</h2>

            <div className="container-carousel">

                <AliceCarousel
                    mouseTracking
                    controlsStrategy={'responsive'}
                    items={items}
                    infinite={true}
                    responsive={{
                        0: {
                            items: 3,
                        },
                        992: {
                            items: 3,
                        },
                        1200: {
                            items: 4,
                        },
                        1350: {
                            items: 5,
                        }
                    }} />
            </div>

        </div>
    )
}
