// ----- Constants -----
import { jobs } from "../jobs";
import { sectors } from "../sectors";
import { technos } from "../technos";

// --- Tools
import SapIllu from "../../../images/partners/SAP.png";
import UiPathIllu from "../../../images/partners/UiPath-logo.png";
import TesseractOcrIllu from "../../../images/partners/Tesseract_OCR.png";
import AlizOcrIllu from "../../../images/partners/aliz-ocr.png";
import ExcelIllu from "../../../images/partners/excel.png";
import OutlookIllu from "../../../images/partners/outlook.png";
import AkioIllu from "../../../images/partners/akio.png";
import EBPIllu from "../../../images/partners/ebp.png";
import ImpotsGouvIllu from "../../../images/partners/impots_gouv.png";
import DatabaseIllu from "../../../images/partners/database.png";
import WwwIllu from "../../../images/partners/www.png";
import PythonIllu from "../../../images/partners/python.png";
import AzureIllu from "../../../images/partners/microsoft-azure.png";
import GsuiteIllu from "../../../images/partners/gsuite.png";
import OneDriveIllu from "../../../images/partners/one_drive.png";
import GoogleChromeIllu from "../../../images/partners/chrome.png";
import AutomationAnywhere from "../../../images/partners/automation_anywhere.png";
import Sharepoint from "../../../images/partners/sharepoint.png";
import HrAccess from "../../../images/partners/hrAccess.png";
import SQLServer from "../../../images/partners/sql_server.png";
import PowerBI from "../../../images/partners/Power-BI.png";
import Oracle from "../../../images/partners/Oracle.png";
import Talend from "../../../images/partners/talend-high.png";
import MicrosoftAccess from "../../../images/partners/microsoft_access.png";
import Winshuttle from "../../../images/partners/winshuttle.png";
import InforM3 from "../../../images/partners/Infor-m3.png";
import Isitrac from "../../../images/partners/isitrac.png";

// ----- File ROI -----
import OCRFacture from "../../../pdf/Lecture_de_facture.pptx.pdf";
import QueueInvoiceProcessing from "../../../pdf/Traitement_factures_file_attente.pptx.pdf";
import AutomationOfBankReconciliation from "../../../pdf/Reconciliation_bancaire.pptx.pdf";
import OrderShipping from "../../../pdf/Expéditions_de_commande.pdf";
import PurchaseOrder from "../../../pdf/Demandes_achat.pptx.pdf";
import ManagementOfManufacturingOrders from "../../../pdf/Gestion_des_ordres_de_fabrications.pptx.pdf";
import AccommodationVacationManagement from "../../../pdf/Gestion_des_vacances_de_logement.pptx.pdf";
import ProductionDeliveryManagement from "../../../pdf/Gestion_des_livraisons_de_productions.pptx.pdf";
import ProcessingDeliveryInformation from "../../../pdf/Traitement_informations_livraisons.pptx.pdf";
import StockUpdate from "../../../pdf/Maj_des_stocks.pptx.pdf";
import ExtractionsLaboratoryInformationManagement from "../../../pdf/Extractions_LIMS.pptx.pdf";
import GestionRemboursementsAvoir from "../../../pdf/Gestion_des_remboursements_avoirs.pptx.pdf";
import DuplicatasFactures from "../../../pdf/Duplicatas_de_factures.pptx.pdf";
import DeclarationDeTVA from "../../../pdf/Declaration_de_TVA.pptx.pdf";
import CollecteReconciliationDeFacturesSurDesPortailsFournisseurs from "../../../pdf/Collecte_et_reconciliation_de_factures_sur_des_portails_fournisseurs.pptx.pdf";
import GenerationDeFacturesAutomatise from "../../../pdf/Generation_de_factures_automatise.pptx.pdf";
import GenerationDeFacturesSAP from "../../../pdf/Generation_de_factures_SAP.pptx.pdf";
import AutomatisationDeLaDeclarationSocialeNominativeDns from "../../../pdf/Automatisation_de_la_declaration_sociale_nominative_dns.pptx.pdf";
import GestionDesODdeSalaire from "../../../pdf/Gestion_des_OD_de_salaire.pptx.pdf";
import AutomatisationEcritureDePaye from "../../../pdf/Automatisation_ecriture_de_paye.pptx.pdf";
import UtilisationRPApourProjetBIGDATA from "../../../pdf/Utilisation_RPA_projet_BIG_DATA.pdf";
import DataCleansingAndMigrationRPA from "../../../pdf/Data_cleansing_and_migration_RPA.pdf";
import AutomatisationGestionArticlesDeteriore from "../../../pdf/Automatisation_gestion_articles_deteriore.pdf";
import VendorMasterDataSAPAndRPA from "../../../pdf/Vendor_master_data_SAP_and_RPA.pdf";
import EmbaucheRH from "../../../pdf/Embauche_RH.pdf";
import CreationDossierClientsEtLiaisonLogicielComptabilite from "../../../pdf/Creation_dossier_clients_et_liaison_logiciel_comptabilite.pdf";
import RobotMajDossierClients from "../../../pdf/Robot_Maj_dossier_clients.pptx.pdf";
import NettoyageDoublons from "../../../pdf/NettoyageDoublons.pptx.pdf";
import RepartitionArrivageCommandes from "../../../pdf/Repartition_arrivage_commandes.pptx.pdf";
import AnnulationDeCommandes from "../../../pdf/Annulation_de_commandes.pptx.pdf";
import GestionDesRetards from "../../../pdf/Gestion_des_retards.pptx.pdf";

// ----- Packages -----
import { v4 as uuidv4 } from "uuid";

// MANQUE TECHNOS SUR CHAQUE PROJET
export const projects = [
  {
    // ________________Done_________________
    id: 1,
    slug: "automatisation-du-processus-de-traitement-de-factures",
    available: true,
    title:
      "Automatisation du processus de traitement de factures en file d’attente",
    description:
      "Le département Finance d’une société du secteur de l’énergie devait régulièrement traiter des factures en attente selon des règles de gestion bien précise. Dans le cas où celles-ci montraient une incohérence avec les règles internes, elle était alors transmise au service concerné.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.ENERGY,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "Récurrent",
      "Ralentit la clôture comptable",
      "File d’attente accumulatrice",
      "Process semi structuré",
      "Ressaisie",
    ],
    manualProcess: [
      "Etape 1: Ouverture du fichier de factures en attente",
      "Etape 2: Identification du problème",
      "Etape 3: Résolution si possible ou transmission au service concerné",
      "Etape 4: Notification par mail de la décision",
      "Etape 5: Changement de statut",
    ],
    automatedProcess: [
      "Etape 1: Identification des règles de gestion",
      "Etape 2: Création d’un fichier central de factures en attente",
      "Etape 3: Automatisation du processus de traitement et de notifications",
      "Etape 4: Formation des métiers au traitements des exceptions",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
    ],
    reportFile: QueueInvoiceProcessing,
  },
  {
    // ________________Done_________________
    id: 2,
    slug: "lecture-ocr-de-factures-et-ajout-dans-sap",
    available: true,
    title: "Lecture (OCR) de factures et ajout dans SAP",
    description:
      "Le département Finance d’une société d’énergie devait pour chaque facture reçue en papier, la scanner puis retranscrire manuellement les éléments sur leur système interne.",
    technos: [technos.RPA, technos.BPM, technos.IA],
    sector: sectors.ENERGY,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "Récurrent",
      "Qualité du scan",
      "Format non structuré",
      "Ressaisies",
      "Archivage",
    ],
    manualProcess: [
      "Etape 1: Réception de la facture papier",
      "Etape 2: Scan de la facture",
      "Etape 3: Lecture des éléments de la facture",
      "Etape 4: Retranscription des éléments dans SAP",
      "Etape 5: Archivage de la facture",
    ],
    automatedProcess: [
      "Etape 1: Analyse des modèles de factures",
      "Etape 2: Automatisation du processus de lecture et retranscription dans SAP",
      "Etape 3: Entrainement de la lecture via Machine learning & IA",
      "Etape 4: Automatisation de l’archivage",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: TesseractOcrIllu,
        alt: "Outil Google Tsseract OCR",
        size: "md",
      },
      {
        image: AlizOcrIllu,
        alt: "Outil Aliz OCR",
        size: "md",
      },
    ],
    reportFile: OCRFacture,
  },
  {
    // ________________Done_________________
    id: 3,
    slug: "automatisation-de-la-reconciliation-bancaire",
    available: true,
    title: "Automatisation de la réconciliation bancaire",
    description:
      "Une société de gestion bancaire devait chaque jour à travers un émulateur des années 90 effectuer des réconciliations bancaires pour identifier de potentielles différences entre les sorties financières réelles et les sorties comptabilisées.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.BANKANDINSURANCE,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "Récurrent",
      "Risque",
      "Interface obsolète",
      "Nombreuses vérifications",
      "Peu de corrections à effectuer",
    ],
    manualProcess: [
      "Etape 1: Ouverture de l’émulateur",
      "Etape 2: Saisie du numéro de DAB",
      "Etape 3: Vérification de la cohérence avec les comptes",
      "Etape 4: Écriture statut du DAB dans le fichier interne",
      "Etape 5: Notification par mail de la synthèse des situations",
    ],
    automatedProcess: [
      "Etape 1: Analyse de l’environnement technique et proposition d’approches",
      "Etape 2: Création d’un fichier central de numéro de DAB et statut",
      "Etape 3: Automatisation du processus et de l’envoie du fichier complété",
      "Etape 4: Explication au métier du modèle de document rendu par le robot",
    ],
    tools: [
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
    ],
    reportFile: AutomationOfBankReconciliation,
  },
  {
    // Done
    id: 4,
    slug: "rpa-vs-data-cleaning-and-migration",
    available: true,
    title: "RPA vs Data cleansing & migration",
    description:
      "Notre client dispose de deux ERP distincts (SAP) dans lesquels la gestion  des master data n’avait aucun lien. Suite à une stratégie de mutualisation  des process métiers et des rapprochements d’équipes, il a été décidé de  migrer l’intégralité des données Fournisseurs/Clients et les flux en  associés dans un des ERP",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.ENERGY,
    job: jobs.IT,
    date: "09/2023",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Pilotage Analyse Méthodologie",
      "Développement de robots",
      "Lean Management",
      "Planning organisation ROI",
      "Infra choix outil",
    ],
    manualProcess: [
      "Etape 1: Extractions manuelles dans SAP. Les extractions sont réalisées pendant 1 à 2 semaines selon les volumes de données",
      "Etape 2: Croisement de données via Excel et découpage des fichiers selon volumes. Etape de travail fastidieuse qui nécessite 2 semaines de traitement en moyenne",
      "Etape 3: Envoi des données au métiers par mail pour validation",
      "Etape 4: Suite à la validation métier, chargement dans SAP via un script ABAP. L’IT intervient selon ses disponibilités. 3 semaines à 2 mois de durée en moyenne",
    ],
    automatedProcess: [
      "Etape 1: Réalisation d’une analyse comparative des écarts entre les SI. Listing  des objets à migrer et stratégie de migrations",
      "Etape 2: Analyse des flux en cours : Factures, Commandes, Contrats, PNS, RIB",
      "Etape 3: Création de scripts et de robots pour nettoyer et migrer les données",
      "Etape 4: Planification des  exports selon les  plannings opérationnels  comptables et achats",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: Sharepoint,
        alt: "Sharepoint",
        size: "xl",
      },
      {
        image: HrAccess,
        alt: "Hr Access",
        size: "xl",
      },
    ],
    reportFile: DataCleansingAndMigrationRPA,
  },
  {
    // Done
    id: 5,
    slug: "expeditions-de-commande",
    available: true,
    title: "Expéditions de commande (création du flux)",
    description:
      "Le service logistique d’une entreprise pharmaceutique passait beaucoup de temps à gérer son stock car il recevait constamment des factures justifiant d’un prélèvement sur le stock longtemps après que ce prélèvement a été effectué, ce qui rendait difficile le passage de commandes et entraînait des ruptures de stock.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.PHARMA,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Gestion du stock",
      "Décision Ad-Hoc",
      "Anonymisation de certaines bases de données",
      "Workflow de validation",
      "Reporting",
    ],
    manualProcess: [
      "Etape 1: Analyse SAP BO des rapports",
      "Etape 2: Exports et traitement sous excel des logs",
      "Etape 3: Création des expéditions",
      "Etape 4: Création de la tournée",
      "Etape 5: Sortie de stock et Facturation",
    ],
    automatedProcess: [
      "Etape 1: Développement des processus et analyses des autorisations requises",
      "Etape 2: Réalisation d'un robot permettant d’analyser et traiter les éléments de commande",
      "Etape 3: Création des expéditions de commandes dans l’ERP et de la tournée en automatique",
      "Etape 4: Reporting et Récupération du numéro d'expédition",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: InforM3,
        alt: "Outil Infor M3",
        size: "xl",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
    ],
    reportFile: OrderShipping,
  },
  {
    // ________________Done_________________
    id: 6,
    slug: "transformation-demande-achat-en-commande-achat",
    available: true,
    title: "Transformation d’une demande d’achat en commande d’achat",
    description:
      "Un grand acteur de l’énergie devait pour chaque demande d’achat, effectuer de nombreuses tâches de vérifications et de paramétrages dans leur ERP SAP afin de passer l’ordre de commande d’achat.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.ENERGY,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "Doubles saisies",
      "Pas de valeur ajoutée",
      "Erreurs",
      "Latence de déclenchement",
      "Manque de coordination",
    ],
    manualProcess: [
      "Etape 1: Demande d’achat reçu par Workflowgen",
      "Etape 2: Lecture du mail",
      "Etape 3: Saisie de la demande d’achat dans SAP",
      "Etape 4: Paramétrage et création de la commande d’achat",
      "Etape 5: Envoi du numéro de commande à Workflowgen",
    ],
    automatedProcess: [
      "Etape 1: Définition des critères de lecture des demandes d’achat",
      "Etape 2: Automatisation des saisies SAP avec paramétrage des actions de création de commandes",
      "Etape 3: Gestion des imprévus du processus (Erreur, doublon, commande existante…)",
      "Etape 4: Formation des utilisateurs à la gestion des erreurs et suivi",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
    ],
    reportFile: PurchaseOrder,
  },
  {
    id: 7,
    slug: "robotisation-et-analyse-répartition-arrivage-commandes",
    available: true,
    title: "Robotisation et analyse de la répartition arrivage commandes",
    description:
      "Une société de pneumatiques passait régulièrement commande auprès de l’usine située à l’étranger et recevait des pneus au fil de la production sans connaître à l’avance leur nature (taille, catégorie, nombre…). Ils devaient donc à chaque arrivage de cargos aller sur le site transporteur et analyser les modèles présents dans chaque conteneur afin d’anticiper l’arrivage pour prévoir la redistribution chez les revendeurs.",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.INDUSTRY,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Chronophages",
      "Récurrent",
      "Anticipation",
      "Nombreuses vérifications",
      "Planification",
    ],
    manualProcess: [
      "Etape 1: Ouverture du site transporteur",
      "Etape 2: Recherches des numéros conteneurs",
      "Etape 3: Extraction des éléments",
      "Etape 4: Récapitulatif dans un fichier Excel",
      "Etape 5: Planification de répartition par revendeur",
    ],
    automatedProcess: [
      "Etape 1: Connexion au site de chaque transporteur en automatique grâce au robot",
      "Etape 2: Automatisation de la recherche d’éléments sur chaque portail avec les règles de gestion spécifiques",
      "Etape 3: Extraction et compilation des éléments sur le ShareDrive avec nomenclature automatique",
      "Etape 4: Reporting dans Excel des articles  en arrivage par semaine",
    ],
    tools: [
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: AutomationAnywhere,
        alt: "Outil Automation Anywhere",
        size: "xs",
      },
    ],
    reportFile: RepartitionArrivageCommandes,
  },
  {
    // ___ Done
    id: 8,
    slug: "automatisation-de-la-gestion-des-ordres-de-fabrication",
    available: true,
    title: "Automatisation de la gestion des ordres de fabrication",
    description:
      "Notre client devait pour chaque ordre de fabrication, une fois le produit terminé, trier les ordres de fabrication et changer leur statut dans le système interne. Pour valider le statut il fallait bien-entendu, passer une vérification sur les écritures.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.INDUSTRY,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "Chronophages",
      "Pas de valeur ajoutée",
      "Temps limité",
      "Doit être rigoureux",
      "Contrôles à faire",
    ],
    manualProcess: [
      "Etape 1: Ouverture de Dynamics NAV",
      "Etape 2: Tri des colonnes",
      "Etape 3: Sélections des Ordres de fabrications disponibles",
      "Etape 4: Contrôle des écritures",
      "Etape 5: Changement de statut et/ou notification par mail",
    ],
    automatedProcess: [
      "Etape 1: Analyse de l’interface et des API disponibles",
      "Etape 2: Récolte des règles de gestion",
      "Etape 3: Automatisation du processus et des notifications de succès ou d’échec du aux vérifications",
      "Etape 4: Explication au métier de la méthode de déclenchement du robot",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
    ],
    reportFile: ManagementOfManufacturingOrders,
  },
  {
    id: 9,
    slug: "automatisation-de-la-gestion-et-analyse-des-logements-vacants",
    available: true,
    title: "Automatisation de la gestion et analyse des logements vacants",
    description:
      "Une société de gestion de parc immobilier devait chaque jour mettre à jour leur base interne en vérifiant les informations des biens immobiliers en vacance locative à travers un fichier Excel qui leur était transmis.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.REALESTATE,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Confidentialité",
      "Récurrence",
      "Besoin de rapidité",
      "Aucune valeur ajoutée",
      "1 seule personne formée dessus",
    ],
    manualProcess: [
      "Etape 1: Extraction manuelle des données dans un outil de gestion des logements",
      "Etape 2: Retraitement et calculs dans Excel manuels",
      "Etape 3: Saisie des informations manquantes et contrôle des écarts",
      "Etape 4: Générer un pdf et une impression",
      "Etape 5: Fermer et passer à l’extraction du  logement suivant",
    ],
    automatedProcess: [
      "Etape 1: Analyse du processus avec l’agent effectuant la manipulation",
      "Etape 2: Analyse de l’environnement technique et proposition d’un scénario",
      "Etape 3: Automatisation du processus et gestion des exceptions de traitement",
      "Etape 4: Formation du collaborateur au déclenchement du robot",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
    ],
    reportFile: AccommodationVacationManagement,
  },
  {
    //______________ Done __________
    id: 10,
    slug: "robotisation-de-la-gestion-des-livraisons-de-productions",
    available: true,
    title: "Robotisation de la gestion des livraisons de productions",
    description:
      "Le département Supply Chain d’une société pharmaceutique devait chaque jour traiter les commandes de leur entrepôts par rapport à leur production centrale. Il fallait ainsi gérer manuellement la répartition des envois de la centrale vers les entrepôts demandeurs.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.PHARMA,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "File d’attente",
      "Récurrence",
      "Gestion de quantité limitée",
      "Priorisation de distribution",
      "Pas de suivi des demandes en attentes",
    ],
    manualProcess: [
      "Etape 1: Réception des quantités demandées par entrepôt",
      "Etape 2: Vérification des quantités disponibles en centrale",
      "Etape 3: Répartition des quantités à envoyer par ancienneté des demandes",
      "Etape 4: Mise en place d’une file d’attente prioritaire",
      "Etape 5: Envoie du fichier récapitulatif à la Supply Chain",
    ],
    automatedProcess: [
      "Etape 1: Récolte des règles de gestion de priorisation et de répartition",
      "Etape 2: Automatisation des envois d’articles",
      "Etape 3: Création d’un fichier de file d’attente",
      "Etape 4: Envoie d’un mail automatisé de notification des actions effectuées",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
    ],
    reportFile: ProductionDeliveryManagement,
  },
  {
    // ________ Manque ROI sur pdf __________
    id: 11,
    slug: "robotisation-du-traitement-des-informations-de-livraisons",
    available: true,
    title: "Robotisation du traitement des informations de livraisons",
    description:
      "Le département Supply Chain d’une société pharmaceutique devait, pour chaque livraison de colis contenant du matériel, récupérer le numéro d’identification et vérifier dans SAP que le colis était bien arrivé afin d’archiver ces informations dans un fichier détaché récapitulatif (Poids, température…).",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.PHARMA,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "Tâche de fond",
      "Nécessaire",
      "Chronophage",
      "Aucune valeur ajoutée",
      "Récurrent",
    ],
    manualProcess: [
      "Etape 1: Récupération du numéro d’identification",
      "Etape 2: Vérification du statut de livraison dans SAP",
      "Etape 3: Récupération des informations (Arrivée, heure, poids, température…)",
      "Etape 4: Création d’un fichier récapitulatif",
      "Etape 5: Envoi du fichier récapitulatif à la Supply Chain",
    ],
    automatedProcess: [
      "Etape 1: Automatisation de la récupération du numéro d’identification",
      "Etape 2: Automatisation des vérifications et de la création du fichier synthèse",
      "Etape 3: Automatisation de l’envoi du fichier synthèse",
      "Etape 4: Formation des métier à la lecture du fichier synthèse et traçabilité robot",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
    ],
    reportFile: ProcessingDeliveryInformation,
  },
  {
    // _________ Process à vrifier (manual process ressemble après automatisation et process auto à "accompagnement")________
    id: 12,
    slug: "robotisation-de-la-mise-a-jour-des-stocks",
    available: true,
    title: "Robotisation de la mise à jour des stocks",
    description:
      "Le département Supply Chain d’une société pharmaceutique devait vérifier toutes les 2 semaines si des articles étaient toujours en stock (présents et non périmés). Ils devaient ainsi effectuer ces vérifications (4 éléments) sur + de 4000 lignes car chaque produit pouvait être présent dans une multitude d’usines ou centrales différentes.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.PHARMA,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "Très récurrent",
      "Multitude d’informations à vérifier",
      "Besoin rapide de l’information",
      "Aucune valeur ajoutée",
      "Conséquence économique",
    ],
    manualProcess: [
      "Etape 1: Supply Chain envoie une liste d’articles à vérifier",
      "Etape 2: Le robot reçoit la liste",
      "Etape 3: La Robot effectue les vérifications (stock, péremption, statut)",
      "Etape 4: Le Robot change le statut dans SAP si nécessaire",
      "Etape 5: Le Robot transmet l’Excel à jour à la Supply Chain",
    ],
    automatedProcess: [
      "Etape 1: Analyse des inputs et outputs requis",
      "Etape 2: Analyse de l’environnement technique des  éléments à vérifier",
      "Etape 3: Automatisation du croisement d’information et des vérifications",
      "Etape 4: Automatisation de la mise à jour des statuts dans SAP",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
    ],
    reportFile: StockUpdate,
  },
  {
    // Manque 2 outils
    id: 13,
    slug: "robotisation-des-extractions-de-bulletin-lims",
    available: true,
    title:
      "Robotisation des extractions de bulletin LIMS (Laboratory Information Management System)",
    description:
      "Un grand acteur français du milieu pharmaceutique devait, chaque jour, rechercher et exporter depuis un logiciel des bulletins d’analyse vers un répertoire spécifique puis les envoyer par mail à chaque client. LIMS* est un outil stratégique et nécessaire pour organiser et optimiser la gestion d’un laboratoire pharmaceutique.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.PHARMA,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "Répétitif",
      "Risque d’erreurs",
      "Temps d’export trop long",
      "Volume conséquent",
      "Problèmes de flux d’approvisionnement",
    ],
    manualProcess: [
      "Etape 1: Récupération d’une liste contenant les noms des bulletins d’analyse",
      "Etape 2: Connexion au logiciel LIMS",
      "Etape 3: Recherche et export du bulletin au format PDF",
      "Etape 4: Envoi par email du bulletin d’analyse au client ",
      "Etape 5: Notification du process owner des actions effectuées par le robot",
    ],
    automatedProcess: [
      "Etape 1: Connexion des robots aux applicatifs (LIMS, Outlook, Adobe) avec Login + MDP",
      "Etape 2: Création du processus automatisé d’export des bulletins d’analyse",
      "Etape 3: Automatisation de la saisie dans le logiciel LIMS",
      "Etape 4: Automatisation des notifications du robot à l’utilisateur",
    ],
    tools: [
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
    ],
    reportFile: ExtractionsLaboratoryInformationManagement,
  },
  {
    // Done
    id: 14,
    slug: "automatisation-de-la-gestion-des-remboursements-et-avoirs",
    available: true,
    title: "Automatisation de la gestion des remboursements et avoirs",
    description:
      "Le processus de traitement des demandes de remboursement ou des avoirs pour les clients qui sont en demande est une tâche récurrente et mobilise les équipes SAV. De plus, il faut s’assurer pour chaque demande qu’elle est légitime. Iil faut récupérer les informations du client et de la commande dans divers environnements ce qui peut complexifier le traitement et le rallonger",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.RETAIL,
    job: jobs.SAV,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "Beaucoup d’étapes de contrôles",
      "Difficulté de gestion des pics de charge",
      "Traitement trop long impactant la satisfaction",
      "Risque d’erreurs important",
      "Informations sensibles",
    ],
    manualProcess: [
      "Etape 1: Réception d’une demande",
      "Etape 2: Vérification du canal",
      "Etape 3: Contrôles des données",
      "Etape 4: Edition du duplicata",
      "Etape 5: Notification & suivi",
    ],
    automatedProcess: [
      "Etape 1: Audit du processus, identification des points clefs, modification du processus manuel avant développement du robot",
      "Etape 2: Développement d’un robot autonome déclenché par la réception d’une demande client dans Outlook",
      "Etape 3: Intégration des multiples règles de gestion de contrôles, le robot travaille sur 5 applications",
      "Etape 4: Reporting automatique des actions effectuées par le robot et de tous les traitement du jour et envoi par mails aux référents métiers",
    ],
    tools: [
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
      {
        image: AkioIllu,
        alt: "Outil SAS Akio",
        size: "md",
      },
    ],
    reportFile: GestionRemboursementsAvoir,
  },
  {
    id: 15,
    slug: "automatisation-des-envois-de-duplicatas-de-factures",
    available: true,
    title: "Automatisation des envois de duplicatas de factures",
    description:
      "Le processus de génération et d’envoie de duplicatas de factures aux clients les demandant est une tâche récurrente et mobilise les équipes SAV. De plus, il faut s’assurer pour chaque demande que le paiement a bien été effectué et validé par le client mais aussi que la demande du client est légitime et non frauduleuse.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.RETAIL,
    job: jobs.SAV,
    date: "06/2020",
    favorite: true,
    key: uuidv4(),
    painPoints: [
      "Beaucoup d’étapes de contrôles",
      "Difficulté de gestion des pics de charge",
      "Traitement trop long impactant la satisfaction",
      "Risque d’erreurs important",
      "Informations sensibles",
    ],
    manualProcess: [
      "Etape 1: Réception d’une demande",
      "Etape 2: Vérification du paiement",
      "Etape 3: Contrôles",
      "Etape 4: Edition du duplicata",
      "Etape 5: Envoi du duplicata",
    ],
    automatedProcess: [
      "Etape 1: Audit du processus, identification des points clefs, modification du processus manuel avant automatisation, revu des notifications et restriction d’accès aux applicatifs",
      "Etape 2: Développement d’un robot autonome déclenché par la réception d’une demande client",
      "Etape 3: ntégration des multiples règles de gestion de contrôles, le robot travaille sur 5 applications",
      "Etape 4: Reporting automatique des actions effectuées par le robot et de tous les traitement du jour et envoi par mails aux référents métiers",
    ],
    tools: [
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
      {
        image: AkioIllu,
        alt: "Outil SAS Akio",
        size: "md",
      },
    ],
    reportFile: DuplicatasFactures,
  },
  {
    id: 16,
    slug: "automatisation-du-processus-de-declaration-tva",
    available: true,
    title: "Automatisation du processus de déclaration de TVA sur impots.gouv",
    description:
      "Un cabinet comptable devait chaque mois déclarer un grand nombre de TVA et souhaitait gagner du temps sur ce processus tout en conservant la main sur les calculs de celle-ci.",
    technos: [technos.RPA, technos.BPM, technos.DEVOPS],
    sector: sectors.SERVICE,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Processus répétitif",
      "Veut garder la main sur le calcul",
      "Peu de temps pour ce processus",
      "Demandes croissantes",
      "Nombreuses doubles saisies",
    ],
    manualProcess: [
      //false
      "Etape 1: Connexion Logiciel",
      "Etape 2: Recherche dossier client",
      "Etape 3: Récupération des données comptables (TVA) + calculs",
      "Etape 4: Réalisation d’un fichier de données comptables  compatible avec l’interface logiciel",
      "Etape 5: Importation fichier csv sur dossier client sur Netexcom",
    ],
    automatedProcess: [
      "Etape 1: Collecte des valeurs à compléter dans chaque champs",
      "Etape 2: Automatisation de la connexion à impôts.gouv",
      "Etape 3: Automatisation de la déclaration, du paiement et de l’information du client par mail",
      "Etape 4: Archivage de tous les justificatifs (paiements et déclarations) automatisé",
    ],
    tools: [
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: EBPIllu,
        alt: "Outil EBP",
        size: "md",
      },
      {
        image: ImpotsGouvIllu,
        alt: "Outil Impots.gouv",
        size: "md",
      },
    ],
    reportFile: DeclarationDeTVA,
  },
  {
    id: 17,
    slug: "automatisation-de-la-collecte-de-documents-comptables-sur-des-portails-fournisseurs-externes",
    available: true,
    title:
      "Collecte et réconciliation de factures sur des portails fournisseurs",
    description:
      "Une entreprise internationale qui fabrique et commercialise des produits issus de la transformation du caoutchouc devait vérifier un nombre important de factures qu’il fallait aller chercher sur le portail web de chaque  constructeur automobile. Un service CSP était en place afin de traiter ces factures récurrentes en allant les chercher sur le site constructeur et en effectuant les vérifications de conformité manuellement.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.INDUSTRY,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Long processus de traitement",
      "Double saisie",
      "Nombreuses erreurs de traitement",
      "Format difficilement lisible",
      "Pas de traçabilité",
    ],
    manualProcess: [
      "Etape 1: Extraction données SAP",
      "Etape 2: Compilation des données et retraitement dans Excel",
      "Etape 3: Vérification des détails de paiement sur un outil SAAS développé par le client",
      "Etape 4: Trier les paiements et télécharger les documents justificatifs",
      "Etape 5: Vérification des correspondances de Paiement entre Excel et SAP",
    ],
    automatedProcess: [
      "Etape 1: Analyse du processus de connexions aux sites constructeurs",
      "Etape 2: Automatisation du processus de récupération et de réconciliation des factures",
      "Etape 3: Création d’un fichier de traçabilité des actions effectués par le robot",
      "Etape 4: Formation des agents et accompagnement au changement",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: DatabaseIllu,
        alt: "Outil base de données",
        size: "xl",
      },
      {
        image: WwwIllu,
        alt: "World wide web",
        size: "sm",
      },
    ],
    reportFile: CollecteReconciliationDeFacturesSurDesPortailsFournisseurs,
  },
  {
    id: 18,
    slug: "automatisation-du-traitement-des-factures",
    available: true,
    title: "Génération de factures automatisé",
    description:
      "Une société luxembourgeoise voyait son back-office surchargé en raison d’un traitement de factures abondant. Plusieurs départements travaillaient à la fois sur un portail interne et sur un ERP (Zoho) engendrant une confusion et une non-structuration de ces traitements.",
    technos: [technos.RPA, technos.BPM, technos.IA, technos.DEVOPS],
    sector: sectors.ENERGY,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Plusieurs interfaces",
      "Pas de processus définie",
      "Erreurs et doublons",
      "Inactif pendant les congés",
      "Pas de reportingé",
    ],
    manualProcess: [
      "Etape 1: Collecte des éléments des bons de commande dans l’ERP",
      "Etape 2: Collecte des informations fournisseurs sur un portail interne",
      "Etape 3: Génération de la facture avec un outil interne, et retraitement manuels",
      "Etape 4: Contrôles de cohérences et validation N+1 au besoin",
      "Etape 5: Envoi par mail de la facture",
    ],
    automatedProcess: [
      "Etape 1: Centralisation du processus en instaurant une data gouvernance",
      "Etape 2: Création d’un robot de génération et envoi par mail automatique de factures",
      "Etape 3: Mise en place des règles d’archivages et historisation des factures",
      "Etape 4: Aide à la clôture comptable en réduisant les traitements manuels et les erreurs",
    ],
    tools: [
      {
        image: GsuiteIllu,
        alt: "Google suite",
        size: "md",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
      {
        image: AzureIllu,
        alt: "Microsoft Azure",
        size: "md",
      },
      {
        image: PythonIllu,
        alt: "Python",
        size: "sm",
      },
    ],
    reportFile: GenerationDeFacturesAutomatise,
  },
  {
    id: 19,
    slug: "robotisation-de-la-generation-de-factures",
    available: true,
    title: "Robotisation de la génération de factures SAP",
    description:
      "Le département Finance d’une société du secteur de l’énergie devait pour générer des factures, le faire manuellement en remplissant un formulaire avec les éléments présents dans SAP.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.ENERGY,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Chronophages",
      "Risque d’erreur",
      "Vérification",
      "Dépendant de SAP",
      "Pas de valeur ajoutée",
    ],
    manualProcess: [
      "Etape 1: Ouvrir SAP, transactions de réception marchandises",
      "Etape 2: Contrôles de cohérence et validation N+1 au besoin",
      "Etape 3: Si erreur, Correction manuelle et remontée d’informations aux interlocuteurs concernés",
      "Etape 4: Sinon, Pré-remplissage de la facture dans SAP",
      "Etape 5: Envoi par mail d’un reporting Excel quotidien",
    ],
    automatedProcess: [
      "Etape 1: Automatisation des règles de déclenchement de la génération d’un formulaire de facturation",
      "Etape 2: Automatisation du remplissage du formulaire",
      "Etape 3: Automatisation de la génération de facture",
      "Etape 4: Envoie de la facture automatisée et archivage",
    ],
    tools: [
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
      {
        image: OneDriveIllu,
        alt: "Outil One Drive",
        size: "md",
      },
    ],
    reportFile: GenerationDeFacturesSAP,
  },
  {
    // Done
    id: 20,
    slug: "automatisation-de-la-gestion-des-articles-deteriores-sap",
    available: true,
    title: "Automatisation de la gestion des articles détériorés SAP",
    description:
      "Automatiser les processus de remplacement des articles détériorés chez les clients pour permettre d’améliorer le flux des demandes de SAV jusqu’à la livraison du produit. Le processus opérationnel impacté en premier est celui de la supply chain et du procurement.",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.INDUSTRY,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Pilotage analyse methodologie",
      "Digitaliser le SAV",
      "Mobilité des commerciaux et techniciens",
      "Workflow de validation",
      "Reporting",
    ],
    manualProcess: [
      "Etape 1: Besoin SAV - Sur le terrain",
      "Etape 2: Envoi des besoins à l’équipe SCM",
      "Etape 3: Validation entre 48h et 72h",
      "Etape 4: Création du flux Commande/Logistique",
      "Etape 5: Livraison client pièce",
    ],
    automatedProcess: [
      "Etape 1: Rédaction du business Process, analyse des pain points et stratégie d’automatisation",
      "Etape 2: Développement d’une interface WEB disponible sur mobile et PC permettant la saisie des bons SAV avec texte et pièces jointes (photos)",
      "Etape 3: Réalisation d’un Workflow de validation, chargement des data dans SAP automatisées et déclenchement automatique des flux SCM",
      "Etape 4: Reporting et suivi des SAV en cours et analyse des délais de traitement",
    ],
    tools: [
      {
        image: Winshuttle,
        alt: "Winshuttle",
        size: "xs",
      },
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: PowerBI,
        alt: "Outil Power BI",
        size: "xl",
      },
    ],
    reportFile: AutomatisationGestionArticlesDeteriore,
  },
  {
    id: 21,
    slug: "automatisation-du-nettoyage-des-doublons",
    available: true,
    title: "Analyse de la qualité de base fournisseur et dédoublonnage",
    description:
      "Un grand acteur de l’énergie dispose de milliers de fournisseurs créés dans SAP. Les données légales sont rarement mises à jour et cela pose de nombreux soucis dans la gouvernance, le reporting, les flux comptables et les flux achats. De plus, de nombreux doublons ont été créés et cela impacte la comptabilité et augmente le risque de fraude. Le client souhaitait ainsi nettoyer les groupes de doublons et traiter automatiquement certaines analyses pour préparer ces actions",
    technos: [technos.BPM, technos.BI],
    sector: sectors.ENERGY,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Doublons",
      "Pas de gouvernance ni d’équipe dédiée",
      "Plusieurs métiers impactés",
      "Données obsolètes et non à jour",
      "Risque de fraude important",
    ],
    manualProcess: [
      "Etape 1: Exports manuels de tables SAP vers un fichier Excel",
      "Etape 2: Enrichissement des données légales et chargement manuel dans SAP",
      "Etape 3: Matching des doublons par ID communs manuellement dans Excel",
      "Etape 4: Identification des doublons par contrat et transactions suite exports SAP et retraitements excel",
      "Etape 5: Mise à jour dans SAP manuellement",
    ],
    automatedProcess: [
      "Etape 1: Création de scripts d’exports de tables SAP dans un excel compilé",
      "Etape 2: Développement et lancement d’un robot permettant d’enrichir les données légales et juridiques des fournisseurs",
      "Etape 3: Identification automatique des contrats inactifs/ doublons (dans Excel)",
      "Etape 4: Nettoyage en masse et enrichissement des données via des scripts et des robots directement dans SAP",
    ],
    tools: [
      {
        image: Winshuttle,
        alt: "Winshuttle",
        size: "xs",
      },
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: PowerBI,
        alt: "Outil Power BI",
        size: "xl",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
    ],
    reportFile: NettoyageDoublons,
  },
  {
    // ______ FALSE : Revoir les process , outils & reportFile (pdf) ________
    id: 22,
    slug: "automatisation-de-la-maintenance-des-data-fournisseurs-sap",
    available: true,
    title: "Automatisation de la maintenance des Master Data fournisseurs SAP",
    description:
      "Les Master Data fournisseurs font l’objet de multiples traitement manuels dans SAP pour notre client. Un fournisseur est une donnée à fort enjeu car tout part de ce point d’entrée pour les commandes, contrats et les factures. Les données peu qualifiées et non maintenues à jour régulièrement, ce qui est un vrai problème. Il fallait les actualiser régulièrement, cela prenait du temps, engendrait des erreurs dans les processus achats et comptables. Les données juridiques des fournisseurs n’étaient pas bien gérées et l’analyse ad-hoc était inefficace et les audits ont pénalisés plusieurs fois financièrement notre client.",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.ENERGY,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Volume de données (45 000 fournisseurs)",
      "Processus de maintenance et création manuel (mail/excel)",
      "Erreurs et doublons fréquents",
      "Risque de fraude accrue",
      "0 responsabilisation sur les données (acheteur et comptable)",
    ],
    manualProcess: [
      "Etape 1: Besoin SAV - Sur le terrain",
      "Etape 2: Envoi des besoins à l’équipe SCM",
      "Etape 3: Validation",
      "Etape 4: Création du flux Commande/Logistique",
      "Etape 5: Livraison client (processus complet pouvant prendre de 5 à 12 jours)",
    ],
    automatedProcess: [
      "Etape 1: Demande des acheteurs (via formulaire WEB)",
      "Etape 2: Workflow de validation (via SharePoint)",
      "Etape 3: Traitement Data Team avec intégration automatisée",
      "Etape 4: Enrichissement de données et nettoyage robotisé",
      "Etape 5: Reporting automatisé (processus complet pouvant de 1 à 4h)",
    ],
    tools: [
      {
        image: Winshuttle,
        alt: "Winshuttle",
        size: "xs",
      },
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: PowerBI,
        alt: "Outil Power BI",
        size: "xl",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "md",
      },
    ],
    reportFile: VendorMasterDataSAPAndRPA,
  },
  {
    // ______ FALSE : Revoir les process , outils & reportFile (pdf) ________
    id: 23,
    slug: "automatisation-des-envois-de-duplicatas-factures",
    available: false,
    title: "Automatisation des envois de duplicatas de factures",
    description:
      "Le processus de génération et d’envoie de duplicatas de factures aux clients les demandant est une tâche récurrente et mobilise les équipes SAV. De plus, il faut s’assurer pour chaque demande que le paiement a bien été effectué et validé par le client mais aussi que la demande du client est légitime et non frauduleuse.",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.BANKANDINSURANCE,
    job: jobs.RH,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Beaucoup d’étapes de contrôles",
      "Difficulté de gestion des pics de charge",
      "Pas de traçabilité des duplicatas envoyés",
      "Risque d’erreurs important",
      "Informations sensibles",
    ],
    manualProcess: [
      "Etape 1: Réception d’une demande",
      "Etape 2: Vérification du paiement",
      "Etape 3: Contrôles",
      "Etape 4: Edition du duplicata",
      "Etape 5: Envoi du duplicata",
    ],
    automatedProcess: [
      "Etape 1: Audit du processus, identification des points clefs, modification du processus manuel avant automatisation, revu des notifications et restriction d’accès aux applicatifs",
      "Etape 2: Développement d’un robot autonome déclenché par la réception d’une demande client",
      "Etape 3: Intégration des multiples règles de gestion de contrôles, le robot travaille sur 5 applications",
      "Etape 4: Reporting automatique des actions effectuées par le robot et de tous les traitement du jour et envoi par mails aux référents métiers",
    ],
    tools: [],
    reportFile: false,
  },
  {
    id: 24,
    slug: "automatisation-de-la-declaration-sociale-nominative",
    available: true,
    title: "Automatisation de la Déclaration Sociale Nominative (DSN)",
    description:
      "Un société de comptabilité devait chaque mois effectuer la Déclaration Sociale Nominative pour chacun de leur client via le site net-entreprises. Ce processus était répétitif et de nombreux justificatifs devaient être conservés tout en fournissant à leur client le certificat de conformité.",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.SERVICE,
    job: jobs.RH,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Processus récurrent",
      "Archivage",
      "Peu de temps pour ce processus",
      "Demandes croissantes",
      "Communication",
    ],
    manualProcess: [
      "Etape 1: Définition des dates de lancement",
      "Etape 2: Recherche client dans EBP",
      "Etape 3: Préparation DSN et mise à jour du taux",
      "Etape 4: Téléchargement justificatifs",
      "Etape 5: Envoie certificat de conformité",
    ],
    automatedProcess: [
      "Etape 1: Automatisation de l’ouverture des dossiers clients sur le logiciel comptable",
      "Etape 2: Automatisation de la connexion à impôts.gouv",
      "Etape 3: Téléchargement de pièces comptables sur le logiciel de comptable",
      "Etape 4: Téléchargements et importations de pièces comptables sur impot.gouv",
    ],
    tools: [
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "xs",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
      {
        image: EBPIllu,
        alt: "Outil EBPIllu",
        size: "xs",
      },
      {
        image: GoogleChromeIllu,
        alt: "Google Chrome",
        size: "xs",
      },
    ],
    reportFile: AutomatisationDeLaDeclarationSocialeNominativeDns,
  },
  {
    id: 25,
    slug: "automatisation-du-traitement-des-annulations-de-commandes",
    available: true,
    title: "Automatisation du traitement des annulations de commandes",
    description:
      "Une entreprise de Retail passait trop de temps à traiter les demandes d’annulation de commande, et ce retard engendrait bien souvent des surcoûts logistiques ainsi qu’une insatisfaction client. De plus, ce processus se répercutait sur plusieurs services ce qui impactait aussi leurs cohésions et performances.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.RETAIL,
    job: jobs.SAV,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Cohésion des services",
      "Accélérer l’annulation de commande",
      "Vérifier la demande d’annulation",
      "Libérer les équipes SAV pour les demandes complexes",
      "Reporting",
    ],
    manualProcess: [
      "Etape 1: Formulaire d’annulation - Reçu par le SAV et à traiter dans les plus brefs délais",
      "Etape 2: Validation de l’annulation - Par le service SAV vérifiant les motifs et justificatifs",
      "Etape 3: Ordre de retour Logistique - Envoi d’un ordre logistique pour organiser le retour",
      "Etape 4: Ordre de remboursement - Ordre comptable pour effectuer le remboursement et la facture associée",
      "Etape 5: Reporting - Des actions effectuées par le robot et les performances du SAV en temps réel",
    ],
    automatedProcess: [
      "Etape 1: Rédaction du business Process, analyse des pain points et stratégie d’automatisation",
      "Etape 2: Mise en place de règles de validation de l’annulation de commandes et maquettes des actions associées",
      "Etape 3: Développement automatisé du processus à travers les différents services impliqués",
      "Etape 4: Formation des utilisateurs au traitement des erreurs et cas complexes",
    ],
    tools: [
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "xs",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
      {
        image: AkioIllu,
        alt: "Outil SAS Akio",
        size: "md",
      },
    ],
    reportFile: AnnulationDeCommandes,
  },
  {
    id: 26,
    slug: "robotisation-de-la-gestion-des-retards-de-commandes",
    available: true,
    title: "Robotisation de la gestion des retards de commande",
    description:
      "De nombreux clients demandaient à avoir des explications quant aux retards de livraisons de leur achat. Ces demandes parfois légitimes et parfois non, devaient être traitées manuellement afin de vérifier si ces demandes étaient justifiées, valides et s’il fallait ouvrir un litige pour retard auprès du transporteur chargé de la livraison des articles.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.RETAIL,
    job: jobs.SAV,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Perte d’argent",
      "Période de forte affluence",
      "Long délai de réponse",
      "Nombreuses vérifications",
      "Impact direct sur la satisfaction client",
    ],
    manualProcess: [
      "Etape 1: Saisie du numéro de commande client dans l’intranet",
      "Etape 2: Tri des types commandes selon achat magasin ou online",
      "Etape 3: Analyse du retard",
      "Etape 4: Ouverture d’un litige chez le transporteur",
      "Etape 5: Envoi d’un mail au client",
    ],
    automatedProcess: [
      "Etape 1: Récolte des critères objectifs de définition du retar",
      "Etape 2: Création du processus automatisé de recherche du statut de livraison",
      "Etape 3: Automatisation de l’envoi de la réponse auprès du client",
      "Etape 4: Automatisation de la création du litige auprès du transporteur concerné",
    ],
    tools: [
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "xs",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
      {
        image: AkioIllu,
        alt: "Outil SAS Akio",
        size: "xs",
      },
      {
        image: Isitrac,
        alt: "Outil Isitrac",
        size: "xl",
      },
    ],
    reportFile: GestionDesRetards,
  },
  {
    // done
    id: 27,
    slug: "utilisation-rpa-pour-projet-big-data",
    available: true,
    title: "Utilisation de la RPA pour un projet BIG DATA",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.RETAIL,
    job: jobs.IT,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Cadrage des process",
      "Workshop (Sales, SCM, Finance, Marketing…)",
      "Data Engineering",
      "Data Warehousing",
      "Data Viz",
    ],
    manualProcess: [
      "Etape 1: Aucun partage d’informations",
      "Etape 2: Aucun Data Model/ Data Dictionnary",
      "Etape 3: Aucune règle de gestion ni Data Gouvernance",
      "Etape 4: Reporting uniquement ad-hoc",
      "Etape 3: Prises de décisions sans impact car à contre-temps",
    ],
    automatedProcess: [
      "Etape 1: Extraction manuelle depuis de multiples sources avec des volumes dépassant le million de lignes",
      "Etape 2: Nettoyage et préparation des données pour intégration dans un Data Mart",
      "Etape 3: Intégration des données structurées dans le Data Mart via des robots et ETL",
      "Etape 4: Création du Data model, liaison de tables, création de requêtes et agrégation de données",
      "Etape 5: Utilisation d’outils de Data Viz connectés au DataMart pour construire le reporting opérationnel automatisé",
    ],
    tools: [
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "xs",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: SQLServer,
        alt: "SQL Server",
        size: "xl",
      },
      {
        image: PowerBI,
        alt: "Power BI",
        size: "md",
      },
      {
        image: Oracle,
        alt: "Oracle",
        size: "xl",
      },
      {
        image: Talend,
        alt: "Talend",
        size: "xs",
      },
      {
        image: MicrosoftAccess,
        alt: "Microsoft Access",
        size: "xl",
      },
    ],
    reportFile: UtilisationRPApourProjetBIGDATA,
  },
  {
    // ______ FALSE - TOUT À REVOIR ________
    id: 28,
    slug: "mise-a-jour-et-controles-des-ecritures-comptables",
    available: false,
    title: "Mise à jour et contrôles des écritures comptables",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.INDUSTRY,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Ut enim ad minima veniam",
      "quis nostrum",
      "ullam corporis",
      "vel illum qui",
      "suscipit",
    ],
    manualProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
      "Etape 5: natus error sit voluptatem accusantium doloremque laudantium",
    ],
    automatedProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
    ],
    tools: [],
    reportFile: false,
  },
  {
    // ______ FALSE - TOUT À REVOIR ________
    id: 29,
    slug: "gestion-des-od-comptables",
    available: false,
    title: "Gestion des OD comptables",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.RETAIL,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Ut enim ad minima veniam",
      "quis nostrum",
      "ullam corporis",
      "vel illum qui",
      "suscipit",
    ],
    manualProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
      "Etape 5: natus error sit voluptatem accusantium doloremque laudantium",
    ],
    automatedProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
    ],
    tools: [],
    reportFile: false,
  },
  {
    // ______ FALSE - TOUT À REVOIR ________
    id: 30,
    slug: "archivage-et-nommage-de-documents-service-credit",
    available: false,
    title: "Archivage et nommage de documents (Service crédit)",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.INDUSTRY,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Ut enim ad minima veniam",
      "quis nostrum",
      "ullam corporis",
      "vel illum qui",
      "suscipit",
    ],
    manualProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
      "Etape 5: natus error sit voluptatem accusantium doloremque laudantium",
    ],
    automatedProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
    ],
    tools: [],
    reportFile: false,
  },
  {
    // ______ FALSE - TOUT À REVOIR ________
    id: 31,
    slug: "renvoi-automatique-de-factures-via-docaposte",
    available: false,
    title: "Renvoi automatique de factures via Docaposte",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.INDUSTRY,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Ut enim ad minima veniam",
      "quis nostrum",
      "ullam corporis",
      "vel illum qui",
      "suscipit",
    ],
    manualProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
      "Etape 5: natus error sit voluptatem accusantium doloremque laudantium",
    ],
    automatedProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
    ],
    tools: [],
    reportFile: false,
  },
  {
    // ______ FALSE - TOUT À REVOIR ________
    id: 32,
    slug: "reconciliation-bancaire-shopify-stripe-paypal",
    available: false,
    title: "Réconcilliation bancaire Shopify/Stripe/Paypal",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.PHARMA,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Ut enim ad minima veniam",
      "quis nostrum",
      "ullam corporis",
      "vel illum qui",
      "suscipit",
    ],
    manualProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
      "Etape 5: natus error sit voluptatem accusantium doloremque laudantium",
    ],
    automatedProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
    ],
    tools: [],
    reportFile: false,
  },
  {
    // ______ FALSE - TOUT À REVOIR ________
    id: 33,
    slug: "gestion-des-demandes-de-capex",
    available: false,
    title: "Gestion des demandes de CAPEX",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN.",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.INDUSTRY,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Ut enim ad minima veniam",
      "quis nostrum",
      "ullam corporis",
      "vel illum qui",
      "suscipit",
    ],
    manualProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
      "Etape 5: natus error sit voluptatem accusantium doloremque laudantium",
    ],
    automatedProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
    ],
    tools: [],
    reportFile: false,
  },
  {
    // ______ FALSE - TOUT À REVOIR ________
    id: 34,
    slug: "creation-des-commandes-d-achat",
    available: false,
    title: "Création des commandes d'achat",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.ENERGY,
    job: jobs.SUPPLYCHAIN,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Ut enim ad minima veniam",
      "quis nostrum",
      "ullam corporis",
      "vel illum qui",
      "suscipit",
    ],
    manualProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
      "Etape 5: natus error sit voluptatem accusantium doloremque laudantium",
    ],
    automatedProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
    ],
    tools: [],
    reportFile: false,
  },
  {
    // ______ FALSE - TOUT À REVOIR ________
    id: 35,
    slug: "traitement-des-campagnes-de-paiement",
    available: false,
    title: "Traitement des campagnes de paiement",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.INDUSTRY,
    job: jobs.RH,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Ut enim ad minima veniam",
      "quis nostrum",
      "ullam corporis",
      "vel illum qui",
      "suscipit",
    ],
    manualProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
      "Etape 5: natus error sit voluptatem accusantium doloremque laudantium",
    ],
    automatedProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
    ],
    tools: [],
    reportFile: false,
  },
  {
    // ______ FALSE - TOUT À REVOIR ________
    id: 36,
    slug: "analyse-des-ecarts-des-avis-d-impots-avec-le-sirh",
    available: false,
    title: "Analyse des écarts des avis d'impots avec le SIRH",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN.",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.SERVICE,
    job: jobs.RH,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Ut enim ad minima veniam",
      "quis nostrum",
      "ullam corporis",
      "vel illum qui",
      "suscipit",
    ],
    manualProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
      "Etape 5: natus error sit voluptatem accusantium doloremque laudantium",
    ],
    automatedProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
    ],
    tools: [],
    reportFile: false,
  },
  {
    // ______ FALSE - TOUT À REVOIR ________
    id: 37,
    slug: "extraction-des-indemnites-journalieres",
    available: false,
    title: "Extraction des indemnités journalières",
    description:
      "Notre client, grand acteur du Retail en Europe souhaite accélérer sa stratégie et rattraper sa concurrence. Les Data pour pouvoir atteindre ces objectifs ne sont pas cartographiées, de mauvaise qualité et inexploitées. L’objectif est de devenir une entreprise DATA-DRIVEN.",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.BANKANDINSURANCE,
    job: jobs.RH,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Ut enim ad minima veniam",
      "quis nostrum",
      "ullam corporis",
      "vel illum qui",
      "suscipit",
    ],
    manualProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
      "Etape 5: natus error sit voluptatem accusantium doloremque laudantium",
    ],
    automatedProcess: [
      "Etape 1: Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 2: perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 3: natus error sit voluptatem accusantium doloremque laudantium",
      "Etape 4: Sit voluptatem accusantium doloremque laudantium",
    ],
    tools: [],
    reportFile: false,
  },
  {
    id: 38,
    slug: "gestion-des-od-de-salaires",
    available: true,
    title: "Gestion des OD de salaires",
    description:
      "Chaque mois, une société de comptabilité devait récupérer des fichiers provenant du logiciel de comptabilité EBP pour les stocker sur leur cloud privé.",
    technos: [technos.RPA, technos.BPM, technos.BI],
    sector: sectors.SERVICE,
    job: jobs.RH,
    date: "06/2023",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Répétitif",
      "Très long",
      "Risque d’erreurs",
      "Réduit la disponibilité de la machine pendant le téléchargement des dossiers comptable",
      "Pas de valeur ajoutée de l’action humaine",
    ],
    manualProcess: [
      "Etape 1: Récupération des informations clients",
      "Etape 2: Ouverture des dossiers clients sur le logiciel de comptabilité",
      "Etape 3: Téléchargement des 4 fichiers comptables",
      "Etape 4: Connexion au Cloud et recherche du dossier client",
      "Etape 5: Dépose des fichiers comptables sur le dossier client dans le Cloud",
    ],
    automatedProcess: [
      "Etape 1: Automatisation de la récupération des données clients",
      "Etape 2: Automatisation de l’ouverture des dossiers clients sur le logiciel comptable",
      "Etape 3: Automatisation d’exportation des fiches comptables",
      "Etape 4: Automatisation d’importation des fiches comptables sur un espace cloud",
    ],
    tools: [
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "xs",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
      {
        image: EBPIllu,
        alt: "Outil EBPIllu",
        size: "xs",
      },
      {
        image: GoogleChromeIllu,
        alt: "Google Chrome",
        size: "xs",
      },
    ],
    reportFile: GestionDesODdeSalaire,
  },
  {
    id: 39,
    slug: "automatisation-des-ecritures-de-paie",
    available: true,
    title: "Automatisation des écritures de paie",
    description:
      "Chaque mois, une société de comptabilité devait créer, modifier et importer un fichier .csv sur la plateforme de comptabilité (Netexcom).",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.SERVICE,
    job: jobs.RH,
    date: "06/2020",
    favorite: false,
    keys: uuidv4(),
    painPoints: [
      "Répétitif",
      "Très long",
      "Risque d’erreurs",
      "Réduit la disponibilité de la machine pendant le téléchargement des dossiers comptables",
      "Pas de valeur ajoutée de l’action humaine",
    ],
    manualProcess: [
      "Etape 1: Identification sur l’espace de Cloud et recherche dossier client ",
      "Etape 2: Récupération du fichier .csv",
      "Etape 3: Modification du fichier .csv",
      "Etape 4: Connexion, identification sur la plateforme de comptabilité (Netexcom) et recherche du dossier client",
      "Etape 5: Importation du fichier .csv",
    ],
    automatedProcess: [
      "Etape 1: Automatisation de l’identification au Cloud",
      "Etape 2: Automatisation de l’exportation de fichier .csv",
      "Etape 3: Automatisation de la modification du fichier .csv",
      "Etape 4: Automatisation de la connexion sur la plateforme comptable et importation des fichiers CSV",
    ],
    tools: [
      {
        image: AutomationAnywhere,
        alt: "Outil Automation Anywhere",
        size: "xs",
      },
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: SapIllu,
        alt: "Outil SAP",
        size: "xs",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
    ],
    reportFile: AutomatisationEcritureDePaye,
  },
  {
    id: 40,
    slug: "gestion-de-l-embauche-d-un-nouveau-salarie",
    available: true,
    title: "Gestion de l'embauche d'un nouveau salarié",
    description:
      "Un grand acteur français de l’énergie devait chaque semaine, pour les nouvelles embauches, effectuer un grand nombre de vérifications et doubles saisies (+ de 100) pour chaque nouveau collaborateur. Cela était à la fois fastidieux et risqué car certains formats étaient compliqués à vérifier et retranscrire (exemple : RIB)",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.ENERGY,
    job: jobs.RH,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Répétitif",
      "Risque d’erreurs",
      "Doubles saisies",
      "Nombreuses vérifications",
      "Format difficilement lisible",
    ],
    manualProcess: [
      "Etape 1: Réception d’une demande",
      "Etape 2: Téléchargement du dossier en PDF",
      "Etape 3: Vérification des éléments",
      "Etape 4: Saisie des éléments dans le SIRH",
      "Etape 5: Notification du responsable RH ",
    ],
    automatedProcess: [
      "Etape 1: Connexion des robots aux applicatifs avec Login + MDP",
      "Etape 2: Création du processus automatisé de vérification des éléments",
      "Etape 3: Automatisation de la saisie dans le logiciel RH interne",
      "Etape 4: Automatisation des notifications du robot à l’utilisateur",
    ],
    tools: [
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
      {
        image: HrAccess,
        alt: "Hr Access",
        size: "xl",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "xs",
      },
    ],
    reportFile: EmbaucheRH,
  },
  {
    id: 41,
    slug: "creation-dossiers-clients-et-liaison-logiciel-comptabilité",
    available: true,
    title:
      "Création des dossiers clients (DSN/PAS) et liaison avec le logiciel de comptabilité",
    description:
      "Au début de chaque année, une société de comptabilité devait créer 2 dossiers pour chaque client, et mettre à jour les chemins de données avec ces nouveaux dossiers. Ensuite, il fallait modifier le taux AT sur le logiciel comptable.",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.SERVICE,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Répétitif",
      "Sans valeur ajouté",
      "Long",
      "Risques d’erreur important",
      "Problème de nommage des clients",
    ],
    manualProcess: [
      "Etape 1: Récupération et création de la liste des clients",
      "Etape 2: Création des dossiers clients",
      "Etape 3: Création des sous dossiers clients avec convention de nommage",
      "Etape 4: Sur le logiciel comptable, mise à jour des chemins d’accès aux dossiers",
      "Etape 5: Sur le logiciel comptable, mise à jour du taux AT",
    ],
    automatedProcess: [
      "Etape 1: Automatisation de la récupération des données d’entrée",
      "Etape 2: Automatisation de la création et du nommage des dossiers clients",
      "Etape 3: Dans le logiciel comptable, ouverture du dossier client et mise à jour des chemins d’accès des dossiers clients",
      "Etape 4: Dans le logiciel comptable, modification taux AT",
    ],
    tools: [
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },
      {
        image: OutlookIllu,
        alt: "Outil Outlook",
        size: "md",
      },
      {
        image: EBPIllu,
        alt: "Outil EBP",
        size: "md",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "xs",
      },
    ],
    reportFile: CreationDossierClientsEtLiaisonLogicielComptabilite,
  },
  {
    id: 42,
    slug: "robot-maj-des-dossiers-clients",
    available: true,
    title: "Robot mises à jour des dossiers clients",
    description:
      "Dans une société de comptabilité, il fallait très régulièrement (plusieurs fois par mois) mettre à jour les données de chaque client dans le logiciel de comptabilité. ",
    technos: [technos.RPA, technos.BPM],
    sector: sectors.SERVICE,
    job: jobs.FINANCE,
    date: "06/2020",
    favorite: false,
    key: uuidv4(),
    painPoints: [
      "Répétitif",
      "Long",
      "Réduit la disponibilité de la machine pendant le téléchargement",
      "Pas de valeur ajoutée de l’action humaine",
      "Régulier",
    ],
    manualProcess: [
      "Etape 1: Récupération des noms de clients",
      "Etape 2: Ouverture du dossier client dans le logiciel de comptabilité",
      "Etape 3: Nombreux clics  pour lancer la mise à jour",
      "Etape 4: Longue attente",
      "Etape 5: Fermeture de mise à jour",
    ],
    automatedProcess: [
      "Etape 1: Automatisation des récupérations de données clients",
      "Etape 2: Automatisation de l’ouverture des dossiers clients",
      "Etape 3: Automatisation de la mise à jour des dossiers clients",
      "Etape 4: Mention sur un fichier excel le résultat du traitement du dossier client",
    ],
    tools: [
      {
        image: ExcelIllu,
        alt: "Outil Excel",
        size: "xl",
      },

      {
        image: EBPIllu,
        alt: "Outil EBP",
        size: "md",
      },
      {
        image: UiPathIllu,
        alt: "Outil UI Path",
        size: "xs",
      },
    ],
    reportFile: RobotMajDossierClients,
  },
];
