import React from 'react'

export default function PrivacyPolicy() {
    return (
        <div>
            <section id="termsAndPolicies" className="component">
                <h1>Politique de protection des données personnelles</h1>
                <div className="subtitle">

                    <h3>
                        OBJET
                    </h3>
                    <p align="JUSTIFY">
                        Cette  Politique  vous  informe  sur  la  façon  dont  vos  données  à
                        caractère personnel sont  collectées et utilisées par la société TED,
                        société par action  simplifiée, au capital de 1 000 €, immatriculée au
                        registre du  commerce et des sociétés de Nanterre sous le numéro 820 308
                        013, domiciliée au 29 Quai Dion Bouton à Puteaux (92800), (ci-après  « Ted
                        ») dans le cadre de l’exploitation du site web  accessible  à l’adresse URL
                        <a href="https://ted.consulting/">https://ted.consulting/</a> en qualité  de
                        responsable du  traitement.
                    </p>
                    <p>
                        Cette  politique  est  accessible  depuis  notre  page  d’accueil  et  en
                        bas  de  chaque  page  du  site  web.
                    </p>
                    <p align="JUSTIFY">
                        Pour plus  d’information sur notre politique groupe en matière de protection
                        des données personnelles nous vous invitons à consulter notre  Politique
                        groupe :
                        <a
                            href="https://www.havas.fr/politique-de-protection-des-donnees-personnelles/"
                        >
                            https://www.havas.fr/politique-de-protection-des-donnees-personnelles/
                        </a>
                        .
                    </p>
                    <br />
                    <h3>
                        DEFINITIONS
                    </h3>
                    <p>
                        Les  termes  ci-dessous,  ont  indifféremment  au  pluriel  comme  au
                        singulier, la signification suivante :
                    </p>
                    <ul>
                        <li>
                            <p>
                                «          donnée à          caractère          personnel » ou
                                «          donnée          personnelle          »          :
                                toute          information          relative          à          une
                                personne          physique          identifiée ou identifiable,
                                directement ou indirectement, au sens          de la
                                réglementation          ;
                            </p>
                        </li>
                        <li>
                            <p>
                                «          personne          concernée          » :
                                personne          physique          faisant          l’objet d’un
                                traitement de données à caractère personnel par          Ted ;
                            </p>
                        </li>
                        <li>
                            <p>
                                «          réglementation          »          :
                                réglementation          en          vigueur          en
                                France, applicable au traitement de données à caractère
                                personnel, notamment le Règlement (UE) 2016/679 du Parlement
                                européen          et          du          Conseil          du
                                27          avril          2016          (le          «
                                RGPD ») et la loi n°78-17 du 6 janvier 1978 « Informatique
                                et Libertés »          modifiée ;
                            </p>
                        </li>
                        <li>
                            <p>
                                «          responsable de traitement » : personne physique ou morale
                                qui          détermine les moyens et finalités d’un
                                traitement          de données          à          caractère
                                personnel au sens de la Réglementation ;
                            </p>
                        </li>
                        <li>
                            <p>
                                «          sous-traitant          »          :          personne
                                physique          ou          morale          qui          traite
                                des          données pour le compte du responsable de traitement
                                dans          le          cadre          d’un          service
                                ou          d’une          prestation          ;
                            </p>
                        </li>
                        <li>
                            <p>
                                « site »          :          site          internet          de
                                Ted          accessible          à          l’adresse          URL :
                                <a href="https://ted.consulting/">https://ted.consulting/</a> ;
                            </p>
                        </li>
                        <li>
                            <p>
                                «          traitement          »          :          toute
                                opération          ou          tout          ensemble
                                d'opérations effectuées ou non à l'aide de procédés automatisés
                                et appliquées à des données ou des ensembles          de données à
                                caractère personnel, tels que la collecte,
                                l'enregistrement, l'organisation, la structuration, la conservation,
                                l'adaptation          ou          la          modification,
                                l'extraction,          la          consultation,
                                l'utilisation, la communication par transmission, la diffusion ou
                                toute autre forme de mise à disposition, le rapprochement ou
                                l'interconnexion, la limitation, l'effacement ou la destruction.
                            </p>
                        </li>
                    </ul>
                    <h1 align="JUSTIFY">
                    </h1>
                    <h1 align="JUSTIFY">
                    </h1>
                    <br />
                    <h3>
                        COMMENT  TRAITONS-NOUS VOS DONNEES A CARACTERE PERSONNEL ?
                    </h3>
                    <h1 align="JUSTIFY">
                    </h1>
                    <p align="JUSTIFY">
                        Lors  de votre navigation et de vos interactions sur le Site, nous pouvons
                        être amenés à collecter et à traiter des données vous  concernant, pour la
                        gestion des activités exercées  pour notre propre compte, en qualité de
                        responsable de  traitement.
                    </p>
                    <p align="JUSTIFY">
                        Ted  respecte le droit de chaque individu, client, fournisseur,  partenaire,
                        candidat, prospect ou internaute d’avoir ses données à  caractère personnel
                        protégées. Nous observons  les  principes  définis  par  la  réglementation
                        :
                    </p>
                    <ul>
                        <li>
                            <p align="JUSTIFY">
                                Traiter          vos données à caractère personnel de manière
                                loyale,          licite          et          transparente.
                            </p>
                        </li>
                        <li>
                            <p align="JUSTIFY">
                                Collecter          vos          données          à
                                caractère          personnel          pour          des
                                finalités          explicite,          déterminées          et
                                légitimes,          et          ne          pas          les
                                traiter ultérieurement de manière incompatible avec ces finalités.
                            </p>
                        </li>
                        <li>
                            <p align="JUSTIFY">
                                S’assurer          que les données à caractère personnel collectées
                                sont          pertinentes, proportionnée et non excessives au regard
                                des          finalités pour lesquelles elles sont collectées et
                                traitées. Les          données à caractère personnel pourront être
                                rendues anonymes          lorsque cela sera possible et approprié,
                                selon la nature des          données et les risques associés aux
                                utilisations prévues.
                            </p>
                        </li>
                        <li>
                            <p align="JUSTIFY">
                                Maintenir          vos          données          à
                                caractère          personnel          exactes,          mises à jour
                                si nécessaire. Nous prendrons des mesures          raisonnables afin
                                de rectifier ou de supprimer les données          inexactes ou
                                incomplètes.
                            </p>
                        </li>
                        <li>
                            <p align="JUSTIFY">
                                Les          données à caractère personnel seront conservées pendant
                                la durée nécessaire au regard des finalités pour lesquelles
                                elles sont collectées et traitées.
                            </p>
                        </li>
                        <li>
                            <p align="JUSTIFY">
                                Le          traitement des données à caractère personnel se fera
                                dans          le respect des droits des personnes.
                            </p>
                        </li>
                        <li>
                            <p align="JUSTIFY">
                                Des          mesures techniques, physiques et organisationnelles
                                sont          prises          pour          sécuriser          vos
                                données          et          empêcher          l’accès          non
                                autorisé,          le          traitement illicite et la perte, la
                                destruction ou l’endommagement          non autorisé ou accidentel
                                des données à caractère personnel.
                            </p>
                        </li>
                        <li>
                            <p align="JUSTIFY">
                                Traiter          vos données à caractère personnel en respectant les
                                bases légales du traitement dont le recueil de consentement lorsque
                                celui-ci est requis.
                            </p>
                        </li>
                    </ul>
                    <br />
                    <h3>
                        QUELS  TYPES DE DONNEES A CARACTERE PERSONNEL SONT COLLECTEES ?
                    </h3>
                    <p>
                        En  notre qualité de responsable du traitement, nous collectons et  traitons
                        vos données à caractère personnel après  vous  en  avoir  informé  de
                        manière  appropriée.
                    </p>
                    <p>
                        Le  type de données à caractère personnel que nous traitons incluent :
                    </p>
                    <ul>
                        <li>
                            <p>
                                Vos          données d’identification : nom, prénom, téléphone
                                adresse          mail
                            </p>
                        </li>
                        <li>
                            <p>
                                Vos          données professionnelles : fonction professionnelle et
                                entreprise de rattachement
                            </p>
                        </li>
                    </ul>
                    <p>
                        Nous  ne collectons pas de données à caractère personnel dites «  sensibles
                        »  ou catégories particulières de données à caractère personnel.
                    </p>
                    <p>
                        Nous  vous informons qu’aucun cookie ou traceur n’est déposé lors de  votre
                        navigation sur le Site. Dès lors, vos données de navigation  et de
                        préférence exprimée ne sont pas traitées par l’Agence.
                    </p>
                    <p>
                        De  manière  générale  et  sauf  exception,  l’utilisation  de  notre  site
                        internet est réservée aux personnes majeures.
                    </p>
                    <h1 align="JUSTIFY">
                    </h1>
                    <h1 align="JUSTIFY">
                    </h1>
                    <br />
                    <h3>
                        QUELLE  EST LA FINALITE, LA BASE JURIDIQUE DE LA COLLECTE DE VOS DONNEES ET
                        LA DUREE DE CONSERVATION ?
                    </h3>
                    <p>
                        Conformément  à la réglementation, les données à caractère personnel  que
                        nous collectons sont utilisées pour l’accomplissement de  finalités pour
                        lesquelles elles ont été collectées  et  ce  pendant  la  durée  indiquée
                        dans  le  tableau  ci-  dessous.
                    </p>
                    <p>
                        Les  traitements  que  nous  mettons  en  œuvre  ont  les  finalités,  bases
                        juridiques et durées suivantes :
                    </p>
                    <table width="100%" border="1" cellpadding="7" cellspacing="0">
                        <colgroup>
                            <col width="195"/>
                            <col width="196"/>
                            <col width="196"/>
                        </colgroup>
                        <tbody>
                            <tr valign="TOP">
                                <td width="195">
                                    <p align="CENTER">
                                        <strong>Finalité</strong>
                                    </p>
                                </td>
                                <td width="196">
                                    <p align="CENTER">
                                        <strong>Base                          Juridique</strong>
                                    </p>
                                </td>
                                <td width="196">
                                    <p align="CENTER">
                                        <strong>Durée</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr valign="TOP">
                                <td width="195">
                                    <p align="LEFT">
                                        Gestion                          et suivi des demandes de
                                        contact
                                    </p>
                                </td>
                                <td width="196">
                                    <p align="LEFT">
                                        Intérêt                          légitime
                                    </p>
                                </td>
                                <td width="196">
                                    <p align="LEFT">
                                        Durée                          nécessaire au traitement de
                                        la demande
                                    </p>
                                </td>
                            </tr>
                            <tr valign="TOP">
                                <td width="195">
                                    <p align="LEFT">
                                        Exercice                          de vos droits en matière
                                        de données à caractère personnel
                                    </p>
                                </td>
                                <td width="196">
                                    <p align="LEFT">
                                        Obligation                          légale et réglementaire
                                    </p>
                                </td>
                                <td width="196">
                                    <p align="LEFT">
                                        1                          an ou 6 ans à compter de la
                                        demande d’exercice du droit, selon
                                        le droit exercé.
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        En  cas de contentieux / procédure, notamment judiciaire, initié avant  le
                        terme des durées ci-dessus et qui nécessiterait  la  conservation des
                        données à caractère personnel notamment en vue de  la constatation, de
                        l’exercice ou de la défense des droits, ces  dernières seront conservées
                        pendant la durée de ladite procédure  et jusqu’à l’extinction définitive des
                        voies de recours.
                    </p>
                    <h1 align="JUSTIFY">
                    </h1>
                    <h1 align="JUSTIFY">
                    </h1>
                    <br />
                    <h3>
                        QUELS  SONT LES DESTINATAIRES DE VOS DONNEES ?
                    </h3>
                    <h1 align="JUSTIFY">
                    </h1>
                    <p align="JUSTIFY">
                        Vos données  à caractère personnel peuvent être communiquées, selon  leurs
                        attributions et habilitations et uniquement si nécessaire pour  leur
                        activité, aux personnels des directions suivantes, en fonction  des
                        finalités strictement poursuivies :
                    </p>
                    <p align="JUSTIFY">
                        Destinataires  internes : direction de la communication, digital,
                        informatique,  ressources humaines, juridique.
                    </p>
                    <p align="JUSTIFY">
                        Destinataires  externes : l’hébergeur de notre site internet, prestataires
                        et  sous-traitants dûment habilités auxquels nous pouvons avoir recours
                        pour la réalisation de nos activités, autorités publiques,  auxiliaires de
                        justice et officiers ministériels le cas échéant.
                    </p>
                    <h1 align="JUSTIFY">
                    </h1>
                    <h1 align="JUSTIFY">
                    </h1>
                    <br />
                    <h3>
                        TRANSFERT  DE DONNEES
                    </h3>
                    <h1 align="JUSTIFY">
                    </h1>
                    <p>
                        Nous  privilégions le transfert de données à caractère personnel au  sein de
                        l’Europe. Néanmoins, vos données à caractère  personnel  collectées  et
                        traitées  dans  le  cadre  des  finalités décrites ci-dessus peuvent être
                        dans certaines  hypothèses transmises à des sociétés hors Union Européenne.
                        Certains  de ces pays présentent un niveau de protection des données
                        adéquat.
                    </p>
                    <p>
                        Dans  les autres hypothèses, nous vous informons que les transferts  de vos
                        données à caractère personnel vers d’autres entités  hors  Union  européenne
                        sont  encadrés  par  la  mise  en  place  de garanties appropriées pour
                        assurer la confidentialité et  la  sécurité  des  données  des  transferts.
                        Nous  pouvons  avoir  recours  dans ce cadre à la conclusion, avec les
                        destinataires de  ces données, de clauses contractuelles conformes aux
                        recommandations  de la Commission européenne permettant de nous assurer que
                        les  garanties appropriées sont prises concernant la  protection  desdites
                        données.
                    </p>
                    <br />
                    <h3>
                        SECURITE  ET SOUS-TRAITANCE
                    </h3>
                    <p>
                        Nous  accordons une importance particulière à la sécurité de vos  données  à
                        caractère  personnel.
                    </p>
                    <p>
                        Pour  cela,  nous  avons  mis  en  place  des  mesures  techniques  et
                        organisationnelles adaptées à la nature des données à  caractère personnel,
                        en vue d’assurer l’intégrité et la  confidentialité de celles-ci et de les
                        protéger contre toute  intrusion malveillante, toute perte, altération ou
                        divulgation à  des tiers non autorisés.
                    </p>
                    <p>
                        Lorsque  nous avons recours à un sous-traitant nous ne lui communiquons  des
                        données à caractère personnel qu’après avoir  obtenu  de  ce  dernier  un
                        engagement  et  des  garanties  sur  sa  capacité  à  répondre  aux
                        exigences  de  sécurité  et  de  confidentialité et conclu un engagement
                        contractuel écrit avec lui.
                    </p>
                    <h1 align="JUSTIFY">
                    </h1>
                    <h1 align="JUSTIFY">
                    </h1>
                    <br />
                    <h3>
                        LES  SITES WEB ET LES RESEAUX SOCIAUX
                    </h3>
                    <h1 align="JUSTIFY">
                    </h1>
                    <p>
                        Notre  site peut fournir des liens vers des applications, produits, services
                        ou sites web tiers pour faciliter votre navigation  et  pour  votre
                        information.  Si  vous  vous  rendez  sur  ces liens, vous quittez le Site.
                        Ted  ne contrôle pas ces sites tiers ni leurs pratiques en termes de
                        confidentialité et de  protection  des  données,  qui  peuvent  être
                        différentes  des  nôtres. Nous déclinons toute responsabilité quant à leur
                        contenu  et leur pratique en termes de protection des données à caractère
                        personnel. Les données à caractère personnel que vous choisissez  de fournir
                        via ces sites ou qui sont recueillies par ces tiers ne  sont pas couvertes
                        par la Politique  protection  des  données  à  caractère  personnel  de  ce
                        site.  Nous  vous  encourageons  à  consulter  la  politique  de  protection
                        des données à caractère personnel de tout site avec  lequel vous
                        interagissez avant de permettre le recueil et  l’utilisation  de  vos
                        données  à  caractère  personnel.  Nous  fournissons  également des liens
                        vers des réseaux. Lorsque vous  utilisez  ces  liens,  des  informations  à
                        votre  sujet  peuvent  être collectées ou partagées. Nous vous encourageons
                        à consulter  les politiques de protection des données à caractère  personnel
                        des  réseaux  sociaux  avec  lesquels  vous  interagissez. En tout état de
                        cause, Ted  ne peut être tenue engagée par ces utilisations de tiers qui ne
                        relèvent pas de sa responsabilité.
                    </p>
                    <h1 align="JUSTIFY">
                    </h1>
                    <h1 align="JUSTIFY">
                    </h1>
                    <br />
                    <h3>
                        QUELS  SONT VOS DROITS EN MATIERE DE DONNEES A CARACTERE PERSONNEL ?
                    </h3>
                    <p>
                        Ted  est particulièrement soucieuse du respect des droits  qui  vous  sont
                        accordés  dans  le  cadre  des  traitements  de données qu’elle met en
                        œuvre, pour vous garantir des  traitements équitables et transparents compte
                        tenu des circonstances  particulières  et  du  contexte  dans  lesquels  vos
                        données  à  caractère  personnel  sont  traitées.
                    </p>
                    <p align="JUSTIFY">
                        Votre  droit  d’accès :  à  ce titre, vous disposez du droit d’obtenir la
                        confirmation  que  vos données à caractère personnel sont ou ne sont pas
                        traitées et  lorsqu’elles le sont, vous disposez du droit de demander une
                        copie  de vos données et des informations concernant :
                    </p>
                    <ul>
                        <li>
                            <p>
                                Les finalités du          traitement ;
                            </p>
                        </li>
                        <li>
                            <p>
                                Les catégories de          données à caractère personnel concernées
                                ;
                            </p>
                        </li>
                        <li>
                            <p>
                                Les destinataires ou          catégories de destinataires ainsi que,
                                le cas échéant si de          telles communication devaient être
                                réalisées, les organisations          internationales auxquelles les
                                données à caractère personnel ont          été ou seront
                                communiquées, en particulier les destinataires qui          sont
                                établis dans des pays tiers ;
                            </p>
                        </li>
                        <li>
                            <p>
                                Lorsque cela est          possible, la durée de conservation des
                                Données à caractère          personnel envisagée ou, lorsque ce
                                n’est pas possible, les          critères utilisés pour déterminer
                                cette durée ;
                            </p>
                        </li>
                        <li>
                            <p>
                                L’existence du          droit de demander au responsable du
                                traitement la rectification ou          l’effacement de vos Données
                                à caractère personnel, du droit de          demander une limitation
                                du Traitement de vos données à caractère          personnel, du
                                droit de vous opposer à ce Traitement ;
                            </p>
                        </li>
                        <li>
                            <p>
                                Le          droit   d’introduire une réclamation auprès d’une
                                autorité de          contrôle ;
                            </p>
                        </li>
                        <li>
                            <p>
                                Des informations          relatives à la source des Données quand
                                elles ne sont pas          collectées directement auprès de vous ;
                            </p>
                        </li>
                        <li>
                            <p>
                                L’existence, le          cas échéant, d’une prise de décision
                                automatisée, y compris de          profilage, et dans ce dernier
                                cas, des informations utiles          concernant la logique
                                sous-jacente, ainsi que l’importance et les          conséquences
                                prévues de ce traitement.
                            </p>
                        </li>
                    </ul>
                    <p align="JUSTIFY">
                        Votre  droit  à  la  rectification  de  vos  données :  vous  pouvez  nous
                        demander  que  vos  données  à  caractère  personnel  soient,  selon  les
                        cas,  rectifiées,  complétées  si  elles  sont  inexactes,  incomplètes,
                        équivoques  et/ou  périmées.
                    </p>
                    <p align="JUSTIFY">
                        Votre  droit  à  l’effacement  de  vos  données :  vous  pouvez nous
                        demander l’effacement de vos données à caractère  personnel dans les cas
                        prévus par la règlementation.  Votre  attention est attirée sur le fait que
                        le droit à l’effacement  des  données  n’est  pas  un  droit  général  et
                        qu’il  ne pourra y être fait droit que si l’un des motifs prévus dans la
                        réglementation  applicable  est  présent.
                    </p>
                    <p align="JUSTIFY">
                        Votre  droit  à  la  limitation  des  traitements  de  données :  vous
                        pouvez demander la limitation du traitement de vos données à  caractère
                        personnel dans les cas prévus par la règlementation.
                    </p>
                    <p align="JUSTIFY">
                        Votre  droit  de  vous  opposer  aux  traitements  de  données :  vous
                        disposez  du  droit  de  vous  opposer  à  tout  moment,  pour  des  raisons
                        tenant à votre situation particulière, à un traitement de  vos données à
                        caractère personnel dont la base juridique est  l’intérêt légitime poursuivi
                        par le responsable de traitement.  En  cas d’exercice d’un tel droit
                        d’opposition, nous veillerons à  ne  plus traiter vos données à caractère
                        personnel dans le cadre du  traitement concerné sauf si nous pouvons
                        démontrer que nous pouvons  avoir des motifs légitimes et impérieux pour
                        maintenir ce  traitement. Ces motifs devront être supérieurs à vos intérêts
                        et  à vos droits et libertés, ou le traitement se justifier pour la
                        constatation, l'exercice ou la défense de droits en justice.
                    </p>
                    <p align="JUSTIFY">
                        Votre  droit  à  la  portabilité  de  vos  données :  vous  disposez du
                        droit à la portabilité de vos données à caractère  personnel. Nous attirons
                        votre attention sur le fait qu’il  ne  s’agit  pas  d’un  droit  général.
                        En  effet,  toutes  les  données  de  tous  les  traitements  ne  sont  pas
                        portables  et  ce  droit ne concerne que les traitements automatisés à
                        l’exclusion  des traitements manuels ou papiers. Ce droit est limité aux
                        traitements dont la base juridique est votre consentement ou  l’exécution
                        des mesures précontractuelles  ou  d’un  contrat.
                    </p>
                    <p align="JUSTIFY">
                        Votre  droit  de  retirer  votre  consentement :  lorsque  les traitements
                        de données que nous mettons en œuvre sont fondés  sur votre consentement,
                        vous pouvez le retirer à n’importe quel  moment. Nous cessons alors de
                        traiter vos données à caractère  personnel sans que les opérations
                        antérieures pour lesquelles vous aviez consenti ne soient  remises en cause.
                    </p>
                    <p align="JUSTIFY">
                        Votre  droit  d’introduire  un  recours :  vous  avez le droit d’introduire
                        une réclamation auprès de l’autorité  de contrôle des données personnelles
                        de votre pays de  résidence et ce sans préjudice de tout autre recours
                        administratif  ou juridictionnel.
                    </p>
                    <p align="JUSTIFY">
                        Votre  droit  de  définir  des  directives  post-mortem :  conformément  à
                        la loi Informatique et libertés du 6 janvier 1978  modifiée, vous avez la
                        possibilité de définir des directives  relatives  à la conservation, à
                        l’effacement et à la communication  de vos données à caractère personnel
                        après votre  décès selon les  modalités  ci-après  définies.
                    </p>
                    <br />
                    <h3>
                        LES  MODALITES D’EXERCICE DE VOS DROITS
                    </h3>
                    <p align="JUSTIFY">
                        Vous pouvez  exercer vos droits auprès du DPO Havas :
                    </p>
                    <p align="JUSTIFY">
                        à  l’adresse électronique : <a href="mailto:dpo@havas.com">dpo@havas.com</a>
                    </p>
                    <p align="JUSTIFY">
                        ou par voie  postale à : Délégué à la Protection des données personnelles
                        Havas, 29/30, quai de Dion Bouton, Puteaux Cedex 92800 France.
                    </p>
                    <p>
                        Nous  vous répondrons dans les meilleurs délais et en tout état  de  cause
                        dans  un  délai  maximum  d’un  (1) mois  à  compter  de  la  réception  de
                        la  demande.  Nous  pourrons,  en  tant  que  de  besoin, prolonger ce délai
                        de deux (2) mois supplémentaires, compte  tenu de la  complexité  et  du
                        nombre  de  demandes,  et  nous  vous  en  informerons spécifiquement.
                    </p>
                    <br />
                    <h3>
                        EVOLUTION  DE LA POLITIQUE DE PROTECTION DES DONNEES A CARACTERE PERSONNEL
                        DU  SITE
                    </h3>
                    <h1 align="JUSTIFY">
                    </h1>
                    <p>
                        Ted  se réserve le droit de modifier cette politique si besoin,  par
                        exemple,  pour  se  conformer à un changement  de  loi,  de règlementation,
                        des pratiques et procédures de Ted, ou des  exigences imposées par les
                        autorités chargées de la protection des  données à caractère personnel.  La
                        nouvelle politique sera publiée sur le Site.
                    </p>
                    <p>
                        Nous  vous  invitons  à  la  consulter  régulièrement.
                    </p>
                    <p>
                        Si  vous avez des questions ou observations sur la présente politique,  nous
                        vous invitons à contacter notre DPO Groupe dont  les coordonnées  figurent
                        à  l’article  ci-dessus.
                    </p>
                    <p align="JUSTIFY">
                        <em>Dernière  mise à jour :</em> 16/12/2024
                    </p>

                </div>
            </section >


        </div >
    )
}
