import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//  ----- Styles  -----
import './styles/App.scss';

//  ----- Components -----
import LocationUrl from './components/tools/Location'
import Loader from './components/tools/LoaderPage'
// import Cursor from './components/tools/Cursor'

//  ----- Sections components -----
import Header from './components/sections/Header'
import Footer from './components/sections/Footer'
import NeedInformations from './components/NeedInformations'

//  ----- Pages  -----
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Solution from './pages/Solution'
import ProjectsFilter from './pages/ProjectsFilter'
import Project from './pages/Project'
import WhoWeAre from './pages/WhoWeAre'
import Partners from './pages/Partners'
import Courses from './pages/Courses'
import OneCourse from './pages/OneCourse'
import Portfolio from './pages/Portfolio'
import TermsAndPolicies from './pages/TermsAndPolicies'
import Blog from './pages/Blog'
import BlogPost from './pages/BlogPost'
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

// ----- Packages -----
import { useMediaQuery } from 'react-responsive'

// ----- Images -----
import Icon from './images/icons/arrow-down.svg'

export default function App() {

  // eslint-disable-next-line
  const [location, setLocation] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [showArrow, setShowArrow] = useState(false)

  // ----- Animation loader -----
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 2500);
    // eslint-disable-next-line
  }, [])


  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 575.98 })
    return isMobile ? children : null
  }

  const scrollTop = () => {
    window.scrollTo(0, 0)
  }

  window.onscroll = function (ev) {
    if (window.scrollY > 400) {
      setShowArrow(true)
    } else {
      setShowArrow(false)
    }
  };
  useEffect(() => {
    scrollTop()
  }, [location.pathname])

  return (
    <Router>

      {!loaded ?

        <Loader />

        :

        <div className="App">

          <Header />

          {/* <Cursor /> */}

          {/* ----- Get URL location ----- */}
          <LocationUrl setLocation={setLocation} />


          <Routes>
            {/* ----- 404 Not Found ----- */}
            <Route path="*" element={<NotFound />} />

            {/* ----- Homepage ----- */}
            <Route exact path="/" element={<Home location={location} />} />

            {/* ___________________ Our Solutions ___________________ */}
            {/* ----- RPA ----- */}
            <Route exact path="/nos-solutions/rpa" element={<Solution location={location} />} />

            {/* ----- BI and Dataviz ----- */}
            <Route exact path="/nos-solutions/bi" element={<Solution location={location} />} />

            {/* ----- IA ----- */}
            <Route exact path="/nos-solutions/ia" element={<Solution location={location} />} />

            {/* ----- Dev Ops ----- */}
            <Route exact path="/nos-solutions/devops" element={<Solution location={location} />} />

            {/* ----- BPM ----- */}
            <Route exact path="/nos-solutions/bpm" element={<Solution location={location} />} />


            {/* ----- Projects by Sectors & Technos----- */}
            <Route exact path="/nos-projets" element={<ProjectsFilter location={location} />} />

            {/* ----- Project ----- */}
            <Route exact path="/nos-projets/:slug" element={<Project />} />


            {/* ___________________ Ressources ___________________*/}
            {/* ----- Who we are ----- */}
            <Route exact path="/qui-sommes-nous" element={<WhoWeAre />} />

            {/* ----- Our Partners ----- */}
            <Route exact path="/partenaires" element={<Partners />} />

            {/* ----- Our Courses ----- */}
            <Route exact path="/formations" element={<Courses />} />

            {/* ----- Project ----- */}
            <Route exact path="/formations/:slug" element={<OneCourse />} />

            {/* ----- Portfolio ----- */}
            <Route exact path="/portfolio" element={<Portfolio />} />

            {/* ----- Conditions & Politiques ----- */}
            <Route exact path="/conditions-et-politiques" element={<TermsAndPolicies />} />

            {/* ----- CGU ----- */}
            <Route exact path="/conditions-generales-utilisation" element={<TermsOfUse />} />

            {/* ----- CGU ----- */}
            <Route exact path="/protection-donnees" element={<PrivacyPolicy />} />

            {/* ----- Blog ----- */}
            <Route exact path="/blog" element={<Blog />} />

            {/* ----- Blog Post----- */}
            <Route exact path="/blog/:slug" element={<BlogPost />} /
            >
          </Routes>

          <NeedInformations />

          <Footer />
          {showArrow &&
            <Mobile>
              <div id="scroll-arrow-top" onClick={scrollTop}>
                <img src={Icon} alt="flèche vers le bas" />
              </div>
            </Mobile>
          }

        </div >
      }

    </Router>
  );
}
