import React from 'react'

export default function TermsAndPolicies() {
    return (
        <div>
            <section id="termsAndPolicies" className="component">
            <h1>Mentions légales</h1>
                <div className="subtitle">

                    <h3>EDITION</h3>
                    <p>Le site web accessible à l’adresse url <a href="https://ted.consulting/">https://ted.consulting/</a> est édité et exploité par la société TED, société par action simplifiée, au capital de 1 000 €, immatriculée au registre du commerce et des sociétés de Nanterre sous le numéro 820 308 013, domiciliée au 29 Quai Dion Bouton à Puteaux (92800).</p>
                    <p>Adresse électronique de contact : <a href="mailto:contact@ted-company.com">contact@ted-company.com</a></p>
                    <p>N° de TVA intracommunautaire : FR04820308013</p>

                    <br />
                    <h3>DIRECTEUR DE LA PUBLICATION</h3>
                    <p>Le directeur de la publication du site web est Monsieur Yoann Denee, en qualité de Président.</p>

                    <br />
                    <h3>HEBERGEMENT</h3>
                    <p>Ce site est hébergé par la société : SQUARESPACE Ireland Ltd, société à responsabilité limitée par actions qui dépend de la législation irlandaise et dont le numéro d’immatriculation est le 527641, et domiciliée au Squarespace House, Ship Street Great, Dublin 8, Irlande, D08 N12C.</p>

                    <br />
                    <h3>HYPERLIENS</h3>
                    <p>Des liens hypertextes peuvent être mis en place dans le cadre de ce site en direction d'autres ressources présentes sur le réseau Internet. Ted ne peut donc exercer de contrôle sur ces ressources externes et ne vérifie pas leur accessibilité, contenu, fonctionnalités ni leur politique de protection des données personnelles. Il est recommandé de prendre connaissance de ces politiques avant de visiter ces sites.</p>
                    <p>Ted ne saurait donc être responsable de l'accès par les utilisateurs à partir des liens hypertextes mis en place dans le cadre du site internet en direction d'autres ressources présentes sur le réseau.</p>
                    <p>En conséquence, Ted décline toute responsabilité quant au contenu des informations fournies sur les ressources présentes sur le réseau au titre de l'activation des liens hypertextes. L’utilisation de ces liens hypertextes relève de la seule et entière responsabilité de l’utilisateur.</p>
                    <p>L'utilisateur ne peut pas, en outre, mettre en place un lien hypertexte en direction de ce site sans l'autorisation expresse et préalable de Ted. En aucun cas, cette autorisation ne pourra être qualifiée de convention implicite d’affiliation.</p>

                    <br />
                    <h3>MODIFICATION DES MENTIONS LÉGALES</h3>
                    <p>Ted se réserve le droit de modifier et d'actualiser les présentes mentions légales à tout moment et sans préavis.</p>
                    <p>L'utilisateur est donc invité à les consulter régulièrement.</p>

                    <p>Dernière mise à jour : 21/11/2024</p>

                </div>
            </section >


        </div >
    )
}
