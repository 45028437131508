import React from 'react'
import { Link } from 'react-router-dom'

// ----- Images -----
import Youtube from '../../images/icons/youtube-green.svg'
import Linkedin from '../../images/icons/linkedin-green.svg'

// ----- Constants -----
import { jobsLabel } from '../../services/constants/components/labelFilter'
import { solutions } from '../../services/constants/components/solutions'

export default function Footer() {


  return (
    <footer>
      <div id="link-component">
        <div id="logo-ted"></div>

        <div className="footer-section">
          <b>Nos solutions</b>
          {solutions && solutions.map((solution, index) =>
            <Link to={solution.link} key={index}>{solution.title}</Link>
          )}
            <Link to={"/"}>Accueil</Link>

        </div>

        <div className="footer-section">
          <b>Métiers</b>
          {jobsLabel && jobsLabel.map((job, index) =>
            <a href={"/nos-projets?jobs=" + job.value} key={index}>{job.title} </a>
          )}


        </div>

        <div className="footer-section">
          <b>Ressources</b>
          <Link to="/partenaires">Nos partenaires</Link>
          <Link to="/formations">Nos formations</Link>
          <Link to="/portfolio">Portfolio</Link>
          <Link to={"/qui-sommes-nous"}>Qui sommes nous?</Link>
          <Link to={"/blog"}>Blog</Link>
          <Link to={"/conditions-et-politiques"}>Mentions légales</Link>
          <Link to={"/conditions-generales-utilisation"}>Conditions générales d'utilisation</Link>
          <Link to={"/protection-donnees"}>Politique de protection des données</Link>
        </div>


        <div id="social-media-icons">
          <a href={process.env.REACT_APP_YOUTUBE_CHANNEL} target="_blank" rel="noopener noreferrer"><img src={Youtube} alt="Youtube rounded" /></a>
          <a href={process.env.REACT_APP_LINKEDIN_CHANNEL} target="_blank" rel="noopener noreferrer"><img src={Linkedin} alt="Linkedin rounded" /></a>
        </div>

      </div>

      <hr />
      <div id="contact-component">
        <b>Contact</b>
        <p>contact@ted-company.com</p>
        {/* <p>Tel: +33 6 22 73 21 25</p> */}
        <p>29 QUAI DION BOUTON, 92800 PUTEAUX</p>
      </div>


    </footer>
  )
}
