import React from 'react'

export default function TermsOfUse() {
    return (
        <div>
            <section id="termsAndPolicies" className="component">
                <h1>Conditions générales d'utilisation</h1>
                <div className="subtitle">

                <p align="JUSTIFY">
                    Bienvenue  sur le site internet <a href="https://ted.consulting/">https://ted.consulting/</a>                     (ci-après le « Site ») édité par la société Ted  (ci-après « Ted »).
                </p>
                <p align="JUSTIFY">
                    Tout  accès ou utilisation du Site est soumis aux présentes Conditions
                    Générales d’Utilisation (ci-après les « Conditions  Générales »).
                </p>
                <p align="JUSTIFY">
                    Nous  vous invitons à lire l’ensemble des Conditions Générales avant
                    d’utiliser le Site.
                </p>
                <h3>
                    <br/>ACCEPTATION ET OPPOSABILITE DES CONDITIONS  GENERALES
                </h3>
                <p align="JUSTIFY">
                    En  accédant et en utilisant le Site, vous acceptez pleinement et
                    entièrement les Conditions Générales et vous vous engagez à vous  y
                    conformer.
                </p>
                <p align="JUSTIFY">
                    Les  Conditions Générales vous sont opposables, réputées lues et
                    applicables, à la date de votre première utilisation du Site et du  seul
                    fait de votre navigation sur le Site.
                </p>
                <p align="JUSTIFY">
                    Vous  pouvez également conserver et/ou imprimer une copie des Conditions
                    Générales pour pouvoir vous y reporter plus tard.
                </p>
                <p align="JUSTIFY">
                    Nous  pouvons procéder, à tout moment et sans préavis, à des  modifications
                    des Conditions Générales. Ces modifications prennent  effet à compter de
                    leur mise en ligne. Le fait de continuer à  utiliser le Site après une
                    modification constitue une acceptation  des modifications dans leur
                    intégralité.
                </p>
                <p align="JUSTIFY">
                    Si  vous n’entendez pas accepter les Conditions Générales, nous vous
                    remercions de cesser votre navigation sur le Site.
                </p>
                <br/>
                <h3>
                    ACCES ET UTILISATION DU SITE
                </h3>
                <p align="JUSTIFY">
                    Le  Site est accessible gratuitement à tout utilisateur disposant d’un
                    accès internet.
                </p>
                <p align="JUSTIFY">
                    Veuillez  noter que l’utilisation du Site est subordonnée à la capacité de
                    votre équipement à se conformer à nos spécifications standard  techniques
                    minimales et de compatibilité. Il vous appartient de  vérifier ces
                    spécifications et de vérifier que votre équipement  est compatible avec nos
                    produits et services.
                </p>
                <p align="JUSTIFY">
                    Tous  les coûts afférents à l'accès au Site et à l’utilisation du  réseau de
                    télécommunication, que ce soient les frais matériels,  logiciels ou d'accès
                    à internet sont exclusivement à votre charge  selon les modalités fixées par
                    ses fournisseurs d’accès et  opérateurs de télécommunication. Vous êtes seul
                    responsable du  bon fonctionnement de votre équipement informatique ainsi
                    que de  votre accès à internet.
                </p>
                <p align="JUSTIFY">
                    Le  Site est mis à jour de façon régulière et des modifications  peuvent par
                    conséquent intervenir à tout moment. Nous nous  réservons, en effet, le
                    droit de supprimer, modifier ou suspendre  l’accès au Site à tout moment
                    sans notification préalable,  notamment pour des raisons de maintenance.
                </p>
                <p align="JUSTIFY">
                    Enfin,  nous ne garantissons pas que tout ou partie du Site soit accessible
                    et/ou adapté à tous les pays du monde.
                </p>
                <p align="JUSTIFY">
                    Lorsque  vous visitez le Site, vous n’êtes pas autorisé à utiliser, ni à
                    permettre à des tiers d’utiliser, tout système automatisé ou  logiciel
                    permettant d’extraire des contenus ou données depuis le  Site à des fins
                    commerciales.
                </p>
                <p align="JUSTIFY">
                    Vous  vous engagez à ne pas utiliser le Site à des fins frauduleuses et à
                    ne pas avoir un comportement qui pourrait porter atteinte à l’image,  aux
                    intérêts et aux droits de Ted ou de tiers, ou à commettre des  actes abusifs
                    ou malveillants tels que :
                </p>
                <ul>
                    <li>
                        <p align="JUSTIFY">
                            Le          piratage, l’introduction de virus ou de code malveillant
                            ;
                        </p>
                    </li>
                    <li>
                        <p align="JUSTIFY">
                            L’accès          ou la tentative d’accès au Site, à ses systèmes ou
                            codes sans          autorisation ;
                        </p>
                    </li>
                    <li>
                        <p align="JUSTIFY">
                            L’altération          ou l’extraction de données ou d’information
                            non autorisée ;
                        </p>
                    </li>
                    <li>
                        <p align="JUSTIFY">
                            L’atteinte          à l’intégrité et/ou à la performance du Site.
                        </p>
                    </li>
                </ul>
                <p align="JUSTIFY">
                    De  manière générale, vous vous engagez à ne pas agir d’une façon  qui
                    pourrait endommager, perturber ou surcharger le Site, en réaliser  une
                    copie, ou empêcher, par quelque moyen que ce soit, son usage et  ses
                    fonctionnalités normales.
                </p>
                <p align="JUSTIFY">
                    Nous  vous informons qu’en cas de violation ou de suspicion légitime de
                    violation des dispositions des Conditions Générales, nous nous  réservons le
                    droit de limiter, de suspendre ou de mettre un terme à  votre accès au Site,
                    et ce en prenant toutes mesures techniques  nécessaires à cette fin et ce
                    sans préjudice de tout éventuel  recours à votre encontre.
                </p>
                <br />
                <h3>FIABILITE DES INFORMATIONS FIGURANT  SUR NOTRE SITE                </h3>
                <p align="JUSTIFY">
                    Les  informations publiées sur le Site ne constituent pas des conseils et
                    ne doivent par conséquent pas être comprises comme tels.
                </p>
                <p align="JUSTIFY">
                    Nous  mettons en œuvre nos meilleurs efforts pour vous fournir des
                    informations de qualité. Néanmoins, l’actualisation, la  précision,
                    l'exactitude ou l'exhaustivité des informations figurant  sur le Site ne
                    peuvent être garanties.
                </p>
                <br />
                <h3>
                    PROPRIETE INTELLECTUELLE ET INDUSTRIELLE
                </h3>
                <p align="JUSTIFY">
                    Le  contenu du Site, sa structure générale ainsi que notamment les
                    logiciels, textes, images animées ou non, illustrations,  photographies,
                    savoir-faire, clips, vidéos, charte graphique  utilisée, et tous les autres
                    éléments composant le Site sont soit  la propriété exclusive de Ted, soit
                    donnés en licence à  celle-ci. <br/>
                    <br/>
                    Toute représentation totale ou partielle du Site  par quelle que personne
                    que ce soit ou sous quelle que forme que ce  soit notamment par
                    téléchargement, reproduction, transmission,  diffusion, affichage,
                    distribution, intégration totale ou partielle  à une œuvre existante, sans
                    l'autorisation expresse, écrite et  préalable de Ted, est interdite.
                </p>
                <p align="JUSTIFY">
                    Il  en est de même des bases de données figurant, le cas échéant sur  le
                    Site qui sont protégées par la réglementation sur la propriété
                    intellectuelle.
                </p>
                <p align="JUSTIFY">
                    Les  marques, noms de domaine, dénominations, slogans, ainsi que les  logos
                    figurant sur le Site et de ses partenaires sont également  juridiquement
                    protégés.
                </p>
                <p align="JUSTIFY">
                    Toute  reproduction totale ou partielle de ces signes distinctifs effectuée
                    à partir des éléments du Site sans autorisation expresse, écrite  et
                    préalable de Ted est prohibée.
                </p>
                <br />
                <h3>
                    SITE TIERS
                </h3>
                <p align="JUSTIFY">
                    Le  Site peut contenir des liens vers des sites et réseaux sociaux de
                    tiers. Ted ne peut être tenu responsable pour les services et  contenus
                    fournis par ces tiers.
                </p>
                <p align="JUSTIFY">
                    En  aucun cas l’existence de liens vers des sites et réseaux sociaux  de
                    tiers emporte la recommandation, la promotion, l’identification  ou la
                    conformité de Ted au regard des déclarations, contenus et  services fournis
                    par ou à travers ces sites tiers. En conséquence,  Ted ne peut être tenu
                    responsable des contenus de tiers ou des  conditions d’utilisation ou
                    politique en matière de protection des  données à caractère personnel de ces
                    sites ou réseaux de tiers ;  vous êtes seul responsable de la vérification
                    et de l’acceptation  des modalités et conditions d’accès et d’utilisation de
                    ces  sites et réseaux de tiers.
                </p>
                <p align="JUSTIFY">
                    Votre  navigation et interaction avec les sites et réseaux de tiers, est
                    soumise aux règles et conditions d’utilisation de chacun de ces  sites, y
                    compris en matière de protection des données à caractère  personnel. Il vous
                    appartient de prendre connaissance des conditions  d’utilisation et
                    politiques applicables sur ces sites et réseaux  avant de les utiliser.
                </p>
                <p align="JUSTIFY">
                    Si  vous décidez de visiter des sites et réseaux de tiers, depuis un  lien
                    présent sur notre Site, vous le faites alors à vos propres  risques. Nous ne
                    sommes pas responsables des contenus, de la véracité  des informations ainsi
                    que des opinions exprimées sur ces sites et  réseaux de tiers.
                </p>
                <br />
                <h3>
                    GARANTIE ET LIMITATION DE RESPONSABILITE
                </h3>
                <p align="JUSTIFY">
                    Nous  mettons en œuvre nos meilleurs efforts pour vous fournir des
                    informations de qualité. Néanmoins, nous ne pouvons garantir  l’exactitude,
                    la précision, l’actualisation ou l’exhaustivité  des informations figurant
                    sur le Site. En conséquence, nous  déclinons toute responsabilité pour les
                    éventuelles imprécisions,  inexactitudes ou omissions portant sur des
                    informations disponibles  sur le Site.
                </p>
                <p align="JUSTIFY">
                    Nous  ne donnons aucune garantie concernant tout ou partie du contenu du
                    Site. <br/>
                    Nous mettons en œuvre des moyens adaptés destinés à  assurer la sécurité des
                    informations présentées sur le Site.  Toutefois, nous ne maîtrisons pas les
                    risques liés au  fonctionnement d’internet et attirons votre attention sur
                    les  risques résultant d’atteinte à la confidentialité des données  sur
                    internet.
                </p>
                <p align="JUSTIFY">
                    En  outre, nous ne donnons aucune garantie que le Site soit exempt de
                    défaut, d’omission et/ou de bugs ou de programmes malveillants.
                </p>
                <p align="JUSTIFY">
                    Par  ailleurs, l’accès au Site pourra être interrompu sans préavis
                    notamment à des fins de maintenance, de sécurité ou de mise à  jour. Notre
                    responsabilité ne saurait être engagée de ce fait,  quels que soient le
                    moment et la durée de cette interruption. Nous  ne pouvons garantir un accès
                    permanent au Site.
                </p>
                <p align="JUSTIFY">
                    Nous  déclinons toute responsabilité en cas de défaillance de  maintenance
                    du Site. <br/>
                    Nous ne pourrons en aucun cas être tenus  responsables de toute défaillance
                    du Site résultant d’une  éventuelle incompatibilité de votre matériel (en ce
                    compris,  notamment, une capacité insuffisante de stockage ou de mémoire).
                    Nous ne pouvons être tenus de supporter les frais que vous pourriez
                    supporter en cas d’assistance ou réparation de votre équipement à  la suite
                    de l’accès au Site.
                </p>
                <p align="JUSTIFY">
                    Nous  ne pouvons être tenus pour responsables, dans la limite des
                    dispositions prévues par la loi, des dommages directs ou indirects,
                    spécifiques, accessoires ou consécutifs, et ce même dans  l’hypothèse où
                    nous aurions été avisés de la possibilité de  tels dommages, découlant de
                    l’accès et/ou de l’utilisation du  Site. Le Site peut contenir des
                    inexactitudes ou des erreurs  typographiques. Nous ne garantissons pas la
                    conformité des contenus  du Site.
                </p>
                <p align="JUSTIFY">
                    Nous  ne pouvons être tenus responsables des éventuelles pertes résultant
                    de vos actions ou inactions à partir de ou sur les contenus  disponibles sur
                    le Site.
                </p>
                <br />
                <h3>
                    DONNEES PERSONNELLES ET COOKIES
                </h3>
                <p align="JUSTIFY">
                    La  protection de vos données personnelles est importante pour nous.  Nous
                    sommes susceptibles de collecter des informations auprès de  vous à partir
                    des formulaires qui sont proposés sur le Site. Pour  en savoir plus sur vos
                    droits en matière de protection des données  personnelles, nous vous
                    remercions de prendre connaissance de  notre
                    <a
                        href="https://www.havas.fr/politique-de-protection-des-donnees-personnelles/"
                    >
                        <u>Politique de Protection de Données Personnelles</u>
                    </a>
                    qui détaille la  façon dont nous traitons vos données personnelles.
                </p>
                <p align="JUSTIFY">
                    Par  ailleurs, nous vous informons qu’aucun cookie ou traceur n’est  déposé
                    lors de votre navigation sur le Site.
                </p>
                <br />
                <h3>
                    DROIT APPLICABLE
                </h3>
                <p align="JUSTIFY">
                    Les  Conditions Générales sont soumises au droit français et toute
                    réclamation ou litige en résultant seront soumis aux Juridictions
                    compétentes. Vous acceptez, de façon expresse et sans réserve que  vous
                    accèderez et utiliserez le Site uniquement et exclusivement, en  l’état,
                    sous votre propre responsabilité et que vous êtes tenu  au respect des lois
                    locales applicables à l’utilisation du Site  et/ou plus généralement au
                    Site.
                </p>
                <p align="JUSTIFY">
                    EN CAS  DE LITIGE AFFERENT A L’ACCES ET L’UTILISATION DU SITE, AVEC DES
                    PROFESSIONNELS ET/OU COMMERCANTS, COMPETENCE EXPRESSE EST ATTRIBUEE  AU
                    TRIBUNAL COMPETENT DU RESSORT DE LA COUR D’APPEL DE PARIS,  NONOBSTANT
                    PLURALITE DE DEFENDEURS OU APPEL EN GARANTIE, MEME POUR  LES PROCEDURES
                    D’URGENCE OU POUR LES PROCEDURES CONSERVATOIRES, EN  REFERE OU PAR REQUETE.
                </p>
                <br />
                <h3>
                    DISPOSITIONS GENERALES
                </h3>
                <p align="JUSTIFY">
                    Dans  l’hypothèse où l’une quelconque des dispositions des Conditions
                    Générales serait déclarée nulle, elle serait supprimée ou  remplacée. Une
                    déclaration de nullité n’affecterait en aucun cas  le reste des dispositions
                    des Conditions Générales.
                </p>
                <p align="JUSTIFY">
                    Le  fait que Ted n’ait pas exigé l’application d’une clause ou  d’un droit
                    résultant d’une quelconque stipulation des Conditions  Générales ne pourra
                    en aucun cas être considéré comme une  renonciation à celle-ci.
                </p>
                <br />
                <h3>
                    CONTACTEZ-NOUS
                </h3>
                <p align="JUSTIFY">
                    Si  vous avez des questions ou demandes au sujet des contenus qui
                    apparaissent sur le Site ou des Conditions Générales vous pouvez  nous
                    contacter à l’adresse suivante :
                </p>
                <p align="JUSTIFY">
                    Par  email : contact@ted-company.com
                    <br /><br />Par  courrier postal :
                    <br />Ted
                    <br />29-30  Quai de Dion Bouton
                    <br />92800  Puteaux
                </p>
                <br />
                <p align="JUSTIFY">
                    <em>Dernière  mise à jour :</em> 16/12/2024
                </p>

                </div>
            </section >


        </div >
    )
}
